export const comune = [
    "1 Decembrie, Ilfov",
    "23 August, Constanța",
    "Abram, Bihor",
    "Abrămuț, Bihor",
    "Acâș, Satu Mare",
    "Acățari, Mureș",
    "Adamclisi, Constanța",
    "Adămuș, Mureș",
    "Adâncata, Ialomița",
    "Adâncata, Suceava",
    "Adășeni, Botoșani",
    "Adunați, Prahova",
    "Adunații-Copăceni, Giurgiu",
    "Afumați, Dolj",
    "Afumați, Ilfov",
    "Agapia, Neamț",
    "Agăș, Bacău",
    "Aghireșu, Cluj",
    "Agigea, Constanța",
    "Agrij, Sălaj",
    "Agriș, Satu Mare",
    "Aita Mare, Covasna",
    "Aiton, Cluj",
    "Albac, Alba",
    "Albeni, Gorj",
    "Albești, Botoșani",
    "Albești, Constanța",
    "Albești, Ialomița",
    "Albești, Mureș",
    "Albești, Vaslui",
    "Albești-Paleologu, Prahova",
    "Albeștii de Argeș, Argeș",
    "Albeștii de Muscel, Argeș",
    "Albota, Argeș",
    "Alexandru I. Cuza, Iași",
    "Alexandru Odobescu, Călărași",
    "Alexandru Vlahuță, Vaslui",
    "Alexandru cel Bun, Neamț",
    "Alexeni, Ialomița",
    "Aliman, Constanța",
    "Alimpești, Gorj",
    "Alma, Sibiu",
    "Almăj, Dolj",
    "Almaș, Arad",
    "Almașu Mare, Alba",
    "Almașu, Sălaj",
    "Alțina, Sibiu",
    "Aluniș, Cluj",
    "Aluniș, Mureș",
    "Aluniș, Prahova",
    "Alunu, Vâlcea",
    "Amărăști, Vâlcea",
    "Amărăștii de Jos, Dolj",
    "Amărăștii de Sus, Dolj",
    "Amaru, Buzău",
    "Amzacea, Constanța",
    "Andrășești, Ialomița",
    "Andreiașu de Jos, Vrancea",
    "Andrid, Satu Mare",
    "Andrieșeni, Iași",
    "Aninoasa, Argeș",
    "Aninoasa, Dâmbovița",
    "Aninoasa, Gorj",
    "Apa, Satu Mare",
    "Apahida, Cluj",
    "Apața, Brașov",
    "Apateu, Arad",
    "Apele Vii, Dolj",
    "Apold, Mureș",
    "Apoldu de Jos, Sibiu",
    "Apostolache, Prahova",
    "Arbore, Suceava",
    "Arcani, Gorj",
    "Archiș, Arad",
    "Arcuș, Covasna",
    "Ardeoani, Bacău",
    "Ardusat, Maramureș",
    "Arefu, Argeș",
    "Argetoaia, Dolj",
    "Ariceștii Rahtivani, Prahova",
    "Ariceștii Zeletin, Prahova",
    "Arieșeni, Alba",
    "Ariniș, Maramureș",
    "Armășești, Ialomița",
    "Armeniș, Caraș-Severin",
    "Aroneanu, Iași",
    "Arpașu de Jos, Sibiu",
    "Arsura, Vaslui",
    "Asău, Bacău",
    "Așchileu, Cluj",
    "Aștileu, Bihor",
    "Asuaju de Sus, Maramureș",
    "Ațel, Sibiu",
    "Atid, Harghita",
    "Ațintiș, Mureș",
    "Augustin, Brașov",
    "Aușeu, Bihor",
    "Avram Iancu, Alba",
    "Avram Iancu, Bihor",
    "Avrămeni, Botoșani",
    "Avrămești, Harghita",
    "Axente Sever, Sibiu",
    "Axintele, Ialomița",
    "Baba Ana, Prahova",
    "Băbăița, Teleorman",
    "Băbana, Argeș",
    "Băbeni, Sălaj",
    "Băbiciu, Olt",
    "Băcani, Vaslui",
    "Băcești, Vaslui",
    "Băcia, Hunedoara",
    "Baciu, Cluj",
    "Bâcleș, Mehedinți",
    "Băgaciu, Mureș",
    "Bahna, Neamț",
    "Bahnea, Mureș",
    "Baia de Criș, Hunedoara",
    "Baia de Fier, Gorj",
    "Baia, Suceava",
    "Baia, Tulcea",
    "Băiculești, Argeș",
    "Băișoara, Cluj",
    "Băița de sub Codru, Maramureș",
    "Băița, Hunedoara",
    "Băiuț, Maramureș",
    "Bala, Mehedinți",
    "Băla, Mureș",
    "Bălăbănești, Galați",
    "Bălăceana, Suceava",
    "Bălăceanu, Buzău",
    "Balaci, Teleorman",
    "Bălăcița, Mehedinți",
    "Balaciu, Ialomița",
    "Bălan, Sălaj",
    "Bălănești, Gorj",
    "Bălășești, Galați",
    "Bălăușeri, Mureș",
    "Balc, Bihor",
    "Balcani, Bacău",
    "Bălcăuți, Suceava",
    "Baldovinești, Olt",
    "Băleni, Dâmbovița",
    "Băleni, Galați",
    "Bălești, Gorj",
    "Bălești, Vrancea",
    "Bălilești, Argeș",
    "Balinț, Timiș",
    "Balotești, Ilfov",
    "Balș, Iași",
    "Balșa, Hunedoara",
    "Balta Albă, Buzău",
    "Balta Doamnei, Prahova",
    "Balta, Mehedinți",
    "Bălțătești, Neamț",
    "Bălțați, Iași",
    "Bâlteni, Gorj",
    "Bălteni, Olt",
    "Bălteni, Vaslui",
    "Bălțești, Prahova",
    "Bălușeni, Botoșani",
    "Bâlvănești, Mehedinți",
    "Banca, Vaslui",
    "Band, Mureș",
    "Băneasa, Constanța",
    "Băneasa, Galați",
    "Băneasa, Giurgiu",
    "Bănești, Prahova",
    "Bănia, Caraș-Severin",
    "Bănișor, Sălaj",
    "Bănița, Hunedoara",
    "Banloc, Timiș",
    "Bâra, Neamț",
    "Bara, Timiș",
    "Bărăganu, Constanța",
    "Bărăganul, Brăila",
    "Bărăști, Olt",
    "Bărbătești, Gorj",
    "Bărbătești, Vâlcea",
    "Bărbulești, Ialomița",
    "Bărbulețu, Dâmbovița",
    "Bârca, Dolj",
    "Bărcănești, Ialomița",
    "Bărcănești, Prahova",
    "Barcani, Covasna",
    "Barcea, Galați",
    "Bârgăuani, Neamț",
    "Bârghiș, Sibiu",
    "Bârla, Argeș",
    "Bârna, Timiș",
    "Bârnova, Iași",
    "Bârsa, Arad",
    "Bârsana, Maramureș",
    "Bârsănești, Bacău",
    "Bârsău, Satu Mare",
    "Bârsești, Vrancea",
    "Baru, Hunedoara",
    "Bârza, Olt",
    "Bârzava, Arad",
    "Bascov, Argeș",
    "Băsești, Maramureș",
    "Bata, Arad",
    "Bățani, Covasna",
    "Batăr, Bihor",
    "Bătarci, Satu Mare",
    "Batoș, Mureș",
    "Bătrâna, Hunedoara",
    "Bătrâni, Prahova",
    "Băuțar, Caraș-Severin",
    "Bazna, Sibiu",
    "Beba Veche, Timiș",
    "Beceni, Buzău",
    "Becicherecu Mic, Timiș",
    "Beciu, Teleorman",
    "Beclean, Brașov",
    "Beica de Jos, Mureș",
    "Beidaud, Tulcea",
    "Belcești, Iași",
    "Belciugatele, Călărași",
    "Beleți-Negrești, Argeș",
    "Belin, Covasna",
    "Belinț, Timiș",
    "Beliș, Cluj",
    "Beliu, Arad",
    "Beltiug, Satu Mare",
    "Benesat, Sălaj",
    "Bengești-Ciocadia, Gorj",
    "Berca, Buzău",
    "Berceni, Ilfov",
    "Berceni, Prahova",
    "Berchișești, Suceava",
    "Bereni, Mureș",
    "Berești-Bistrița, Bacău",
    "Berești-Meria, Galați",
    "Berești-Tazlău, Bacău",
    "Berevoești, Argeș",
    "Berezeni, Vaslui",
    "Berghin, Alba",
    "Berislăvești, Vâlcea",
    "Beriu, Hunedoara",
    "Berlești, Gorj",
    "Berliște, Caraș-Severin",
    "Bertea, Prahova",
    "Berteștii de Jos, Brăila",
    "Berveni, Satu Mare",
    "Berzasca, Caraș-Severin",
    "Berzovia, Caraș-Severin",
    "Berzunți, Bacău",
    "Beștepe, Tulcea",
    "Bethausen, Timiș",
    "Beuca, Teleorman",
    "Bezdead, Dâmbovița",
    "Bicaz, Maramureș",
    "Bicaz-Chei, Neamț",
    "Bicazu Ardelean, Neamț",
    "Bichiș, Mureș",
    "Biertan, Sibiu",
    "Biharia, Bihor",
    "Bilbor, Harghita",
    "Bilca, Suceava",
    "Bilciurești, Dâmbovița",
    "Biled, Timiș",
    "Biliești, Vrancea",
    "Birchiș, Arad",
    "Birda, Timiș",
    "Bisoca, Buzău",
    "Bistra, Alba",
    "Bistra, Maramureș",
    "Bistreț, Dolj",
    "Bistrița Bârgăului, Bistrița-Năsăud",
    "Bivolari, Iași",
    "Bixad, Covasna",
    "Bixad, Satu Mare",
    "Blăgești, Bacău",
    "Blăgești, Vaslui",
    "Blăjani, Buzău",
    "Blăjel, Sibiu",
    "Blăjeni, Hunedoara",
    "Blândești, Botoșani",
    "Blandiana, Alba",
    "Blejești, Teleorman",
    "Blejoi, Prahova",
    "Bobâlna, Cluj",
    "Bobicești, Olt",
    "Bobota, Sălaj",
    "Bocicoiu Mare, Maramureș",
    "Bocșa, Sălaj",
    "Bocsig, Arad",
    "Bod, Brașov",
    "Bodești, Neamț",
    "Bodoc, Covasna",
    "Bogata, Mureș",
    "Bogați, Argeș",
    "Bogda, Timiș",
    "Bogdan Vodă, Maramureș",
    "Bogdana, Teleorman",
    "Bogdana, Vaslui",
    "Bogdand, Satu Mare",
    "Bogdănești, Bacău",
    "Bogdănești, Suceava",
    "Bogdănești, Vaslui",
    "Bogdănița, Vaslui",
    "Boghești, Vrancea",
    "Boghicea, Neamț",
    "Boghiș, Sălaj",
    "Boianu Mare, Bihor",
    "Boișoara, Vâlcea",
    "Boița, Sibiu",
    "Boiu Mare, Maramureș",
    "Bolboși, Gorj",
    "Boldești-Gradiștea, Prahova",
    "Boldu, Buzău",
    "Boldur, Timiș",
    "Bolintin-Deal, Giurgiu",
    "Bolotești, Vrancea",
    "Bolvașnița, Caraș-Severin",
    "Bonțida, Cluj",
    "Borănești, Ialomița",
    "Borăscu, Gorj",
    "Borca, Neamț",
    "Borcea, Călărași",
    "Bordei Verde, Brăila",
    "Bordești, Vrancea",
    "Bordușani, Ialomița",
    "Borlești, Neamț",
    "Boroaia, Suceava",
    "Borod, Bihor",
    "Boroșneu Mare, Covasna",
    "Borș, Bihor",
    "Borșa, Cluj",
    "Bosanci, Suceava",
    "Boșorod, Hunedoara",
    "Boteni, Argeș",
    "Boțești, Argeș",
    "Botești, Neamț",
    "Boțești, Vaslui",
    "Botiz, Satu Mare",
    "Botiza, Maramureș",
    "Botoroaga, Teleorman",
    "Botoșana, Suceava",
    "Botoșești-Paia, Dolj",
    "Bozieni, Neamț",
    "Bozioru, Buzău",
    "Bozovici, Caraș-Severin",
    "Brabova, Dolj",
    "Brădeanu, Buzău",
    "Brădeni, Sibiu",
    "Brădești, Dolj",
    "Brădești, Harghita",
    "Bradu, Argeș",
    "Brăduleț, Argeș",
    "Brăduț, Covasna",
    "Brăești, Botoșani",
    "Brăești, Buzău",
    "Brăești, Iași",
    "Bragadiru, Teleorman",
    "Brăhășești, Galați",
    "Braloștița, Dolj",
    "Bran, Brașov",
    "Brânceni, Teleorman",
    "Brâncovenești, Mureș",
    "Brâncoveni, Olt",
    "Brănești, Dâmbovița",
    "Brănești, Gorj",
    "Brănești, Ilfov",
    "Brănișca, Hunedoara",
    "Braniștea, Bistrița-Năsăud",
    "Braniștea, Dâmbovița",
    "Braniștea, Galați",
    "Braniștea, Mehedinți",
    "Brastavățu, Olt",
    "Bratca, Bihor",
    "Brateiu, Sibiu",
    "Brateș, Covasna",
    "Bratovoești, Dolj",
    "Brazi, Prahova",
    "Brazii, Arad",
    "Breasta, Dolj",
    "Breaza, Buzău",
    "Breaza, Mureș",
    "Breaza, Suceava",
    "Brebeni, Olt",
    "Brebu Nou, Caraș-Severin",
    "Brebu, Caraș-Severin",
    "Brebu, Prahova",
    "Brestovăț, Timiș",
    "Brețcu, Covasna",
    "Bretea Română, Hunedoara",
    "Breznița-Motru, Mehedinți",
    "Breznița-Ocol, Mehedinți",
    "Brezoaele, Dâmbovița",
    "Brodina, Suceava",
    "Broscăuți, Botoșani",
    "Broșteni, Mehedinți",
    "Broșteni, Vrancea",
    "Bruiu, Sibiu",
    "Brusturi, Bihor",
    "Brusturi, Neamț",
    "Brusturoasa, Bacău",
    "Bucerdea Grânoasă, Alba",
    "Buceș, Hunedoara",
    "Buchin, Caraș-Severin",
    "Bucinișu, Olt",
    "Bucium, Alba",
    "Buciumeni, Dâmbovița",
    "Buciumeni, Galați",
    "Buciumi, Bacău",
    "Buciumi, Sălaj",
    "Bucoșnița, Caraș-Severin",
    "Bucov, Prahova",
    "Bucovăț, Dolj",
    "Bucovăț, Timiș",
    "Bucșani, Dâmbovița",
    "Bucșani, Giurgiu",
    "Bucu, Ialomița",
    "Bucureșci, Hunedoara",
    "Buda, Buzău",
    "Budacu de Jos, Bistrița-Năsăud",
    "Budeasa, Argeș",
    "Budești, Bistrița-Năsăud",
    "Budești, Maramureș",
    "Budești, Vâlcea",
    "Budila, Brașov",
    "Budureasa, Bihor",
    "Buduslău, Bihor",
    "Buești, Ialomița",
    "Bughea de Jos, Argeș",
    "Bughea de Sus, Argeș",
    "Buhoci, Bacău",
    "Bujoreni, Teleorman",
    "Bujoreni, Vâlcea",
    "Bujoru, Teleorman",
    "Bulbucata, Giurgiu",
    "Bulz, Bihor",
    "Bulzești, Dolj",
    "Bulzeștii de Sus, Hunedoara",
    "Bumbești-Pițic, Gorj",
    "Bunești, Brașov",
    "Bunești, Suceava",
    "Bunești, Vâlcea",
    "Bunești-Averești, Vaslui",
    "Bunila, Hunedoara",
    "Buntești, Bihor",
    "Burila Mare, Mehedinți",
    "Burjuc, Hunedoara",
    "Burla, Suceava",
    "Bustuchin, Gorj",
    "Butea, Iași",
    "Buteni, Arad",
    "Butimanu, Dâmbovița",
    "Butoiești, Mehedinți",
    "Buturugeni, Giurgiu",
    "Buza, Cluj",
    "Buzescu, Teleorman",
    "Buzoești, Argeș",
    "C.A. Rosetti, Buzău",
    "C.A. Rosetti, Tulcea",
    "Căbești, Bihor",
    "Cacica, Suceava",
    "Căianu Mic, Bistrița-Năsăud",
    "Căianu, Cluj",
    "Câineni, Vâlcea",
    "Căiuți, Bacău",
    "Calafindești, Suceava",
    "Călărași, Botoșani",
    "Călărași, Cluj",
    "Călărași, Dolj",
    "Călățele, Cluj",
    "Căldăraru, Argeș",
    "Călinești, Argeș",
    "Călinești, Maramureș",
    "Călinești, Teleorman",
    "Călinești-Oaș, Satu Mare",
    "Călmățuiu de Sus, Teleorman",
    "Călmățuiu, Teleorman",
    "Câlnic, Alba",
    "Câlnic, Gorj",
    "Calopăr, Dolj",
    "Călugăreni, Giurgiu",
    "Călugăreni, Prahova",
    "Călui, Olt",
    "Calvini, Buzău",
    "Camăr, Sălaj",
    "Cămărașu, Cluj",
    "Cămărzana, Satu Mare",
    "Cămin, Satu Mare",
    "Câmpani, Bihor",
    "Câmpineanca, Vrancea",
    "Câmpulung la Tisa, Maramureș",
    "Câmpuri, Vrancea",
    "Cândești, Botoșani",
    "Cândești, Dâmbovița",
    "Cândești, Neamț",
    "Cănești, Buzău",
    "Căpâlna, Bihor",
    "Căpâlnița, Harghita",
    "Căpleni, Satu Mare",
    "Căpreni, Gorj",
    "Capu Câmpului, Suceava",
    "Căpușu Mare, Cluj",
    "Cărand, Arad",
    "Carașova, Caraș-Severin",
    "Carastelec, Sălaj",
    "Caraula, Dolj",
    "Cărbunari, Caraș-Severin",
    "Cărbunești, Prahova",
    "Carcaliu, Tulcea",
    "Cârcea, Dolj",
    "Cârjiți, Hunedoara",
    "Cârlibaba, Suceava",
    "Cârligele, Vrancea",
    "Cârlogani, Olt",
    "Cârna, Dolj",
    "Carpen, Dolj",
    "Cărpinet, Bihor",
    "Cărpiniș, Timiș",
    "Cârța, Harghita",
    "Cârța, Sibiu",
    "Cârțișoara, Sibiu",
    "Căscioarele, Călărași",
    "Cășeiu, Cluj",
    "Casimcea, Tulcea",
    "Cașin, Bacău",
    "Castelu, Constanța",
    "Castranova, Dolj",
    "Cața, Brașov",
    "Catalina, Covasna",
    "Catane, Dolj",
    "Câțcău, Cluj",
    "Căteasca, Argeș",
    "Cătina, Buzău",
    "Cătina, Cluj",
    "Cătunele, Gorj",
    "Căuaș, Satu Mare",
    "Cavadinești, Galați",
    "Căzănești, Mehedinți",
    "Cazasu, Brăila",
    "Ceahlău, Neamț",
    "Ceamurlia de Jos, Tulcea",
    "Ceanu Mare, Cluj",
    "Ceatalchioi, Tulcea",
    "Cefa, Bihor",
    "Cehal, Satu Mare",
    "Ceica, Bihor",
    "Celaru, Dolj",
    "Cenad, Timiș",
    "Cenade, Alba",
    "Cenei, Timiș",
    "Cepari, Argeș",
    "Ceplenița, Iași",
    "Ceptura, Prahova",
    "Cerașu, Prahova",
    "Cerăt, Dolj",
    "Cerbăl, Hunedoara",
    "Cerchezu, Constanța",
    "Cergău, Alba",
    "Cermei, Arad",
    "Cerna, Tulcea",
    "Cernat, Covasna",
    "Cernătești, Buzău",
    "Cernătești, Dolj",
    "Cernești, Maramureș",
    "Cernica, Ilfov",
    "Cernișoara, Vâlcea",
    "Certeju de Sus, Hunedoara",
    "Cerțești, Galați",
    "Certeze, Satu Mare",
    "Ceru-Băcăinți, Alba",
    "Cervenia, Teleorman",
    "Cetariu, Bihor",
    "Cetate, Bistrița-Năsăud",
    "Cetate, Dolj",
    "Cetatea de Baltă, Alba",
    "Cetățeni, Argeș",
    "Ceuașu de Câmpie, Mureș",
    "Cezieni, Olt",
    "Checea, Timiș",
    "Cherechiu, Bihor",
    "Chețani, Mureș",
    "Chevereșu Mare, Timiș",
    "Chiajna, Ilfov",
    "Chibed, Mureș",
    "Chichiș, Covasna",
    "Chieșd, Sălaj",
    "Chiheru de Jos, Mureș",
    "Chilia Veche, Tulcea",
    "Chiliile, Buzău",
    "Chinteni, Cluj",
    "Chiochiș, Bistrița-Năsăud",
    "Chiojdeanca, Prahova",
    "Chiojdeni, Vrancea",
    "Chiojdu, Buzău",
    "Chirnogeni, Constanța",
    "Chirnogi, Călărași",
    "Chirpăr, Sibiu",
    "Chiscani, Brăila",
    "Chiselet, Călărași",
    "Chisindia, Arad",
    "Chișlaz, Bihor",
    "Chiuiești, Cluj",
    "Chiuza, Bistrița-Năsăud",
    "Cicănești, Argeș",
    "Cicârlău, Maramureș",
    "Ciceu, Harghita",
    "Ciceu-Giurgești, Bistrița-Năsăud",
    "Ciceu-Mihăiești, Bistrița-Năsăud",
    "Ciclova Română, Caraș-Severin",
    "Cilibia, Buzău",
    "Cilieni, Olt",
    "Cincu, Brașov",
    "Ciobanu, Constanța",
    "Ciocănești, Călărași",
    "Ciocănești, Dâmbovița",
    "Ciocănești, Suceava",
    "Ciocani, Vaslui",
    "Ciocârlia, Constanța",
    "Ciocârlia, Ialomița",
    "Ciochina, Ialomița",
    "Ciocile, Brăila",
    "Ciofrângeni, Argeș",
    "Ciohorăni, Iași",
    "Ciolănești, Teleorman",
    "Ciolpani, Ilfov",
    "Ciomăgești, Argeș",
    "Ciorani, Prahova",
    "Ciorăști, Vrancea",
    "Ciorogârla, Ilfov",
    "Cioroiași, Dolj",
    "Ciortești, Iași",
    "Ciprian Porumbescu, Suceava",
    "Cireșu, Brăila",
    "Cireșu, Mehedinți",
    "Cislău, Buzău",
    "Ciucea, Cluj",
    "Ciuchici, Caraș-Severin",
    "Ciucsângeorgiu, Harghita",
    "Ciucurova, Tulcea",
    "Ciudanovița, Caraș-Severin",
    "Ciugud, Alba",
    "Ciulnița, Ialomița",
    "Ciumani, Harghita",
    "Ciumeghiu, Bihor",
    "Ciumești, Satu Mare",
    "Ciuperceni, Gorj",
    "Ciuperceni, Teleorman",
    "Ciupercenii Noi, Dolj",
    "Ciurea, Iași",
    "Ciurila, Cluj",
    "Ciuruleasa, Alba",
    "Cizer, Sălaj",
    "Cleja, Bacău",
    "Clejani, Giurgiu",
    "Clinceni, Ilfov",
    "Coarnele Caprei, Iași",
    "Coaș, Maramureș",
    "Cobadin, Constanța",
    "Cobia, Dâmbovița",
    "Cochirleanca, Buzău",
    "Cociuba Mare, Bihor",
    "Cocora, Ialomița",
    "Cocorăștii Colț, Prahova",
    "Cocorăștii Mislii, Prahova",
    "Cocu, Argeș",
    "Codăești, Vaslui",
    "Cogealac, Constanța",
    "Cojasca, Dâmbovița",
    "Cojocna, Cluj",
    "Colceag, Prahova",
    "Colelia, Ialomița",
    "Colibași, Giurgiu",
    "Colonești, Bacău",
    "Colonești, Olt",
    "Coltău, Maramureș",
    "Colți, Buzău",
    "Comăna, Brașov",
    "Comana, Constanța",
    "Comana, Giurgiu",
    "Comandău, Covasna",
    "Comănești, Suceava",
    "Comarna, Iași",
    "Comișani, Dâmbovița",
    "Comloșu Mare, Timiș",
    "Concești, Botoșani",
    "Conop, Arad",
    "Constantin Daicoviciu, Caraș-Severin",
    "Conțești, Dâmbovița",
    "Conțești, Teleorman",
    "Copăcel, Bihor",
    "Copăcele, Caraș-Severin",
    "Copăceni, Ilfov",
    "Copăceni, Vâlcea",
    "Copălău, Botoșani",
    "Copalnic-Mănăștur, Maramureș",
    "Corbasca, Bacău",
    "Corbeanca, Ilfov",
    "Corbeni, Argeș",
    "Corbi, Argeș",
    "Corbii Mari, Dâmbovița",
    "Corbița, Vrancea",
    "Corbu, Constanța",
    "Corbu, Harghita",
    "Corbu, Olt",
    "Corcova, Mehedinți",
    "Cordăreni, Botoșani",
    "Cordun, Neamț",
    "Corlățel, Mehedinți",
    "Corlăteni, Botoșani",
    "Cornățelu, Dâmbovița",
    "Cornea, Caraș-Severin",
    "Cornereva, Caraș-Severin",
    "Cornești, Cluj",
    "Cornești, Dâmbovița",
    "Cornetu, Ilfov",
    "Corni, Botoșani",
    "Corni, Galați",
    "Cornu Luncii, Suceava",
    "Cornu, Prahova",
    "Corod, Galați",
    "Coroieni, Maramureș",
    "Coroiești, Vaslui",
    "Coroisânmărtin, Mureș",
    "Coronini, Caraș-Severin",
    "Corunca, Mureș",
    "Corund, Harghita",
    "Cosâmbești, Ialomița",
    "Coșbuc, Bistrița-Năsăud",
    "Coșeiu, Sălaj",
    "Coșereni, Ialomița",
    "Coșești, Argeș",
    "Cosmești, Galați",
    "Cosmești, Teleorman",
    "Cosminele, Prahova",
    "Coșna, Suceava",
    "Cosoba, Giurgiu",
    "Coșoveni, Dolj",
    "Costache Negri, Galați",
    "Coșteiu, Timiș",
    "Costești, Buzău",
    "Costești, Iași",
    "Costești, Vâlcea",
    "Costești, Vaslui",
    "Costeștii din Vale, Dâmbovița",
    "Costinești, Constanța",
    "Costișa, Neamț",
    "Costuleni, Iași",
    "Coșula, Botoșani",
    "Coteana, Olt",
    "Cotești, Vrancea",
    "Cotmeana, Argeș",
    "Cotnari, Iași",
    "Coțofănești, Bacău",
    "Coțofenii din Dos, Dolj",
    "Coțofenii din Față, Dolj",
    "Coțușca, Botoșani",
    "Covăsânț, Arad",
    "Cozieni, Buzău",
    "Cozma, Mureș",
    "Cozmeni, Harghita",
    "Cozmești, Iași",
    "Cozmești, Vaslui",
    "Crăcăoani, Neamț",
    "Crăciunelu de Jos, Alba",
    "Crăciunești, Mureș",
    "Craidorolț, Satu Mare",
    "Crăiești, Mureș",
    "Craiva, Arad",
    "Crâmpoia, Olt",
    "Crângeni, Teleorman",
    "Crângu, Teleorman",
    "Crângurile, Dâmbovița",
    "Crasna, Gorj",
    "Crasna, Sălaj",
    "Creaca, Sălaj",
    "Crețeni, Vâlcea",
    "Crețești, Vaslui",
    "Crevedia Mare, Giurgiu",
    "Crevedia, Dâmbovița",
    "Crevenicu, Teleorman",
    "Cricău, Alba",
    "Criciova, Timiș",
    "Crișan, Tulcea",
    "Crișcior, Hunedoara",
    "Crișeni, Sălaj",
    "Cristești, Botoșani",
    "Cristești, Iași",
    "Cristești, Mureș",
    "Cristian, Brașov",
    "Cristian, Sibiu",
    "Cristinești, Botoșani",
    "Criștioru de Jos, Bihor",
    "Cristolț, Sălaj",
    "Crivăț, Călărași",
    "Crizbav, Brașov",
    "Crucea, Constanța",
    "Crucea, Suceava",
    "Crucișor, Satu Mare",
    "Crușeț, Gorj",
    "Cuca, Argeș",
    "Cuca, Galați",
    "Cucerdea, Mureș",
    "Cuci, Mureș",
    "Cucuteni, Iași",
    "Cudalbi, Galați",
    "Cujmir, Mehedinți",
    "Culciu, Satu Mare",
    "Cumpăna, Constanța",
    "Cungrea, Olt",
    "Cupșeni, Maramureș",
    "Curățele, Bihor",
    "Curcani, Călărași",
    "Curtea, Timiș",
    "Curtești, Botoșani",
    "Curtișoara, Olt",
    "Curtuișeni, Bihor",
    "Cut, Alba",
    "Cuza Vodă, Călărași",
    "Cuza Vodă, Constanța",
    "Cuza Vodă, Galați",
    "Cuzăplac, Sălaj",
    "Cuzdrioara, Cluj",
    "Dăbâca, Cluj",
    "Dăeni, Tulcea",
    "Dăești, Vâlcea",
    "Dagâța, Iași",
    "Daia Română, Alba",
    "Daia, Giurgiu",
    "Dalboșeț, Caraș-Severin",
    "Dalnic, Covasna",
    "Dâmbovicioara, Argeș",
    "Dămienești, Bacău",
    "Dămuc, Neamț",
    "Dănciulești, Gorj",
    "Dăneasa, Olt",
    "Daneș, Mureș",
    "Dănești, Gorj",
    "Dănești, Harghita",
    "Dănești, Vaslui",
    "Daneți, Dolj",
    "Dângeni, Botoșani",
    "Dănicei, Vâlcea",
    "Dărăști-Ilfov, Ilfov",
    "Dârjiu, Harghita",
    "Dârlos, Sibiu",
    "Dârmănești, Argeș",
    "Dărmănești, Dâmbovița",
    "Dărmănești, Suceava",
    "Darova, Timiș",
    "Dârvari, Mehedinți",
    "Dascălu, Ilfov",
    "Davidești, Argeș",
    "Dealu Morii, Bacău",
    "Dealu, Harghita",
    "Deda, Mureș",
    "Deleni, Constanța",
    "Deleni, Iași",
    "Deleni, Vaslui",
    "Delești, Vaslui",
    "Densuș, Hunedoara",
    "Denta, Timiș",
    "Derna, Bihor",
    "Dersca, Botoșani",
    "Desa, Dolj",
    "Desești, Maramureș",
    "Devesel, Mehedinți",
    "Deveselu, Olt",
    "Dezna, Arad",
    "Dichiseni, Călărași",
    "Diculești, Vâlcea",
    "Didești, Teleorman",
    "Dieci, Arad",
    "Dimăcheni, Botoșani",
    "Dimitrie Cantemir, Vaslui",
    "Diosig, Bihor",
    "Dioști, Dolj",
    "Ditrău, Harghita",
    "Doba, Satu Mare",
    "Dobârceni, Botoșani",
    "Dobârlău, Covasna",
    "Dobra, Dâmbovița",
    "Dobra, Hunedoara",
    "Dobreni, Neamț",
    "Dobrești, Argeș",
    "Dobrești, Bihor",
    "Dobrești, Dolj",
    "Dobrețu, Olt",
    "Dobrin, Sălaj",
    "Dobroești, Ilfov",
    "Dobromir, Constanța",
    "Dobrosloveni, Olt",
    "Dobroteasa, Olt",
    "Dobrotești, Dolj",
    "Dobrotești, Teleorman",
    "Dobrovăț, Iași",
    "Dobrun, Olt",
    "Dochia, Neamț",
    "Doclin, Caraș-Severin",
    "Dodești, Vaslui",
    "Dofteana, Bacău",
    "Dognecea, Caraș-Severin",
    "Doicești, Dâmbovița",
    "Dolhești, Iași",
    "Dolhești, Suceava",
    "Doljești, Neamț",
    "Domașnea, Caraș-Severin",
    "Domnești, Argeș",
    "Domnești, Ilfov",
    "Dor Mărunt, Călărași",
    "Dorna Candrenilor, Suceava",
    "Dorna-Arini, Suceava",
    "Dornești, Suceava",
    "Dorobanți, Arad",
    "Dorobanțu, Călărași",
    "Dorobanțu, Tulcea",
    "Dorolț, Satu Mare",
    "Doștat, Alba",
    "Dracea, Teleorman",
    "Drăcșenei, Teleorman",
    "Dragalina, Călărași",
    "Drăgănești de Vede, Teleorman",
    "Drăgănești, Bihor",
    "Drăgănești, Galați",
    "Drăgănești, Neamț",
    "Drăgănești, Prahova",
    "Drăgănești-Vlașca, Teleorman",
    "Drăganu, Argeș",
    "Drăgești, Bihor",
    "Drăghiceni, Olt",
    "Dragodana, Dâmbovița",
    "Drăgoești, Ialomița",
    "Drăgoești, Vâlcea",
    "Drăgoiești, Suceava",
    "Dragomirești, Dâmbovița",
    "Dragomirești, Neamț",
    "Dragomirești, Vaslui",
    "Dragomirești-Vale, Ilfov",
    "Dragoș Vodă, Călărași",
    "Dragoslavele, Argeș",
    "Drăgotești, Dolj",
    "Drăgotești, Gorj",
    "Dragu, Sălaj",
    "Drăguș, Brașov",
    "Drăgușeni, Botoșani",
    "Drăgușeni, Galați",
    "Drăgușeni, Iași",
    "Drăgușeni, Suceava",
    "Drăguțești, Gorj",
    "Drajna, Prahova",
    "Drânceni, Vaslui",
    "Drănic, Dolj",
    "Dridu, Ialomița",
    "Dubova, Mehedinți",
    "Duda-Epureni, Vaslui",
    "Dudești, Brăila",
    "Dudeștii Noi, Timiș",
    "Dudeștii Vechi, Timiș",
    "Dulcești, Neamț",
    "Dumbrava Roșie, Neamț",
    "Dumbrava, Mehedinți",
    "Dumbrava, Prahova",
    "Dumbrava, Timiș",
    "Dumbrăveni, Constanța",
    "Dumbrăveni, Suceava",
    "Dumbrăveni, Vrancea",
    "Dumbrăvești, Prahova",
    "Dumbrăvița, Brașov",
    "Dumbrăvița, Maramureș",
    "Dumbrăvița, Timiș",
    "Dumești, Iași",
    "Dumești, Vaslui",
    "Dumitra, Bistrița-Năsăud",
    "Dumitrești, Vrancea",
    "Dumitrița, Bistrița-Năsăud",
    "Durnești, Botoșani",
    "Eftimie Murgu, Caraș-Severin",
    "Epureni, Vaslui",
    "Erbiceni, Iași",
    "Eremitu, Mureș",
    "Ernei, Mureș",
    "Eșelnița, Mehedinți",
    "Estelnic, Covasna",
    "Ezeriș, Caraș-Severin",
    "Făcăeni, Ialomița",
    "Făgețelu, Olt",
    "Fălciu, Vaslui",
    "Fălcoiu, Olt",
    "Fântâna Mare, Suceava",
    "Fântânele, Arad",
    "Fântânele, Constanța",
    "Fântânele, Iași",
    "Fântânele, Mureș",
    "Fântânele, Prahova",
    "Fântânele, Suceava",
    "Fântânele, Teleorman",
    "Fărăgău, Mureș",
    "Faraoani, Bacău",
    "Fărău, Alba",
    "Fărcaș, Dolj",
    "Fărcașa, Maramureș",
    "Farcașa, Neamț",
    "Fărcașele, Olt",
    "Fărcășești, Gorj",
    "Fârdea, Timiș",
    "Fârliug, Caraș-Severin",
    "Fârțănești, Galați",
    "Fârtățești, Vâlcea",
    "Făurei, Neamț",
    "Făurești, Vâlcea",
    "Feldioara, Brașov",
    "Feldru, Bistrița-Năsăud",
    "Feleacu, Cluj",
    "Feliceni, Harghita",
    "Felnac, Arad",
    "Ferești, Vaslui",
    "Fibiș, Timiș",
    "Fildu de Jos, Sălaj",
    "Filipeni, Bacău",
    "Filipești, Bacău",
    "Filipeștii de Pădure, Prahova",
    "Filipeștii de Târg, Prahova",
    "Finiș, Bihor",
    "Finta, Dâmbovița",
    "Fitionești, Vrancea",
    "Fizeșu Gherlii, Cluj",
    "Florești, Cluj",
    "Florești, Mehedinți",
    "Florești, Prahova",
    "Florești-Stoenești, Giurgiu",
    "Florica, Buzău",
    "Focuri, Iași",
    "Foeni, Timiș",
    "Foieni, Satu Mare",
    "Foltești, Galați",
    "Forăști, Suceava",
    "Forotic, Caraș-Severin",
    "Frâncești, Vâlcea",
    "Frăsinet, Călărași",
    "Frăsinet, Teleorman",
    "Frata, Cluj",
    "Frătăuții Noi, Suceava",
    "Frătăuții Vechi, Suceava",
    "Frătești, Giurgiu",
    "Frecăței, Brăila",
    "Frecăței, Tulcea",
    "Frumoasa, Harghita",
    "Frumoasa, Teleorman",
    "Frumosu, Suceava",
    "Frumușani, Călărași",
    "Frumușeni, Arad",
    "Frumușica, Botoșani",
    "Frumușița, Galați",
    "Fruntișeni, Vaslui",
    "Fulga, Prahova",
    "Fundata, Brașov",
    "Fundeni, Călărași",
    "Fundeni, Galați",
    "Fundu Moldovei, Suceava",
    "Furculești, Teleorman",
    "Gâdinți, Neamț",
    "Găgești, Vaslui",
    "Găiceana, Bacău",
    "Găiseni, Giurgiu",
    "Gălănești, Suceava",
    "Gălăteni, Teleorman",
    "Galații Bistriței, Bistrița-Năsăud",
    "Gălăuțaș, Harghita",
    "Galbenu, Brăila",
    "Gălbinași, Buzău",
    "Gălbinași, Călărași",
    "Galda de Jos, Alba",
    "Gălești, Mureș",
    "Gâlgău, Sălaj",
    "Galicea Mare, Dolj",
    "Galicea, Vâlcea",
    "Galiciuica, Dolj",
    "Găneasa, Ilfov",
    "Găneasa, Olt",
    "Gănești, Mureș",
    "Gângiova, Dolj",
    "Gârbău, Cluj",
    "Gârbou, Sălaj",
    "Gârbova, Alba",
    "Gârbovi, Ialomița",
    "Gârceni, Vaslui",
    "Gârcina, Neamț",
    "Gârcov, Olt",
    "Gârda de Sus, Alba",
    "Gârdani, Maramureș",
    "Gârla Mare, Mehedinți",
    "Gârleni, Bacău",
    "Gârliciu, Constanța",
    "Gârnic, Caraș-Severin",
    "Garoafa, Vrancea",
    "Găujani, Giurgiu",
    "Găvănești, Olt",
    "Gavojdia, Timiș",
    "Geaca, Cluj",
    "Gemenele, Brăila",
    "General Berthelot, Hunedoara",
    "George Enescu, Botoșani",
    "Gepiu, Bihor",
    "Ghelari, Hunedoara",
    "Ghelința, Covasna",
    "Gheorghe Doja, Ialomița",
    "Gheorghe Doja, Mureș",
    "Gheorghe Lazăr, Ialomița",
    "Gherăești, Neamț",
    "Gherăseni, Buzău",
    "Ghercești, Dolj",
    "Ghergheasa, Buzău",
    "Gherghești, Vaslui",
    "Gherghița, Prahova",
    "Gherța Mică, Satu Mare",
    "Ghidfalău, Covasna",
    "Ghidici, Dolj",
    "Ghidigeni, Galați",
    "Ghilad, Timiș",
    "Ghimeș-Făget, Bacău",
    "Ghimpați, Giurgiu",
    "Ghimpețeni, Olt",
    "Ghindăoani, Neamț",
    "Ghindărești, Constanța",
    "Ghindari, Mureș",
    "Ghindeni, Dolj",
    "Ghioroc, Arad",
    "Ghioroiu, Vâlcea",
    "Ghiroda, Timiș",
    "Ghizela, Timiș",
    "Giarmata, Timiș",
    "Giera, Timiș",
    "Gighera, Dolj",
    "Gilău, Cluj",
    "Gioseni, Bacău",
    "Girișu de Criș, Bihor",
    "Giroc, Timiș",
    "Girov, Neamț",
    "Giubega, Dolj",
    "Giulești, Maramureș",
    "Giulvăz, Timiș",
    "Giurgeni, Ialomița",
    "Giurgița, Dolj",
    "Giuvărăști, Olt",
    "Glăvănești, Bacău",
    "Glăvile, Vâlcea",
    "Glimboca, Caraș-Severin",
    "Glina, Ilfov",
    "Glodeanu Sărat, Buzău",
    "Glodeanu-Siliștea, Buzău",
    "Glodeni, Dâmbovița",
    "Glodeni, Mureș",
    "Glogova, Gorj",
    "Godeanu, Mehedinți",
    "Godeni, Argeș",
    "Godinești, Gorj",
    "Gogoșari, Giurgiu",
    "Gogoșu, Dolj",
    "Gogoșu, Mehedinți",
    "Gohor, Galați",
    "Goicea, Dolj",
    "Goiești, Dolj",
    "Golăiești, Iași",
    "Golești, Vâlcea",
    "Golești, Vrancea",
    "Gologanu, Vrancea",
    "Gorban, Iași",
    "Gorbănești, Botoșani",
    "Gorgota, Prahova",
    "Gornești, Mureș",
    "Gornet, Prahova",
    "Gornet-Cricov, Prahova",
    "Goruia, Caraș-Severin",
    "Gostavățu, Olt",
    "Gostinari, Giurgiu",
    "Gostinu, Giurgiu",
    "Gottlob, Timiș",
    "Grădina, Constanța",
    "Grădinari, Caraș-Severin",
    "Grădinari, Giurgiu",
    "Grădinari, Olt",
    "Grădinile, Olt",
    "Grădiștea, Brăila",
    "Grădiștea, Călărași",
    "Grădiștea, Ilfov",
    "Grădiștea, Vâlcea",
    "Grajduri, Iași",
    "Grămești, Suceava",
    "Grăniceri, Arad",
    "Grănicești, Suceava",
    "Gratia, Teleorman",
    "Greaca, Giurgiu",
    "Grebănu, Buzău",
    "Grebenișu de Câmpie, Mureș",
    "Grecești, Dolj",
    "Greci, Mehedinți",
    "Greci, Tulcea",
    "Grindu, Ialomița",
    "Grindu, Tulcea",
    "Grințieș, Neamț",
    "Grivița, Galați",
    "Grivița, Ialomița",
    "Grivița, Vaslui",
    "Grojdibodu, Olt",
    "Gropeni, Brăila",
    "Gropnița, Iași",
    "Groși, Maramureș",
    "Groșii Țibleșului, Maramureș",
    "Grozești, Iași",
    "Grozești, Mehedinți",
    "Gruia, Mehedinți",
    "Gruiu, Ilfov",
    "Grumăzești, Neamț",
    "Gugești, Vrancea",
    "Gura Caliței, Vrancea",
    "Gura Foii, Dâmbovița",
    "Gura Ialomiței, Ialomița",
    "Gura Ocniței, Dâmbovița",
    "Gura Padinii, Olt",
    "Gura Râului, Sibiu",
    "Gura Șuții, Dâmbovița",
    "Gura Teghii, Buzău",
    "Gura Vadului, Prahova",
    "Gura Văii, Bacău",
    "Gura Vitioarei, Prahova",
    "Gurahonț, Arad",
    "Gurasada, Hunedoara",
    "Gurbănești, Călărași",
    "Gurghiu, Mureș",
    "Gușoeni, Vâlcea",
    "Hăghig, Covasna",
    "Hălăucești, Iași",
    "Hălchiu, Brașov",
    "Hălmăgel, Arad",
    "Hălmagiu, Arad",
    "Halmășd, Sălaj",
    "Halmeu, Satu Mare",
    "Hamcearca, Tulcea",
    "Hănești, Botoșani",
    "Hangu, Neamț",
    "Hănțești, Suceava",
    "Hărău, Hunedoara",
    "Hărman, Brașov",
    "Hărmănești, Iași",
    "Hârseni, Brașov",
    "Hârsești, Argeș",
    "Hârtiești, Argeș",
    "Hârtop, Suceava",
    "Hășmaș, Arad",
    "Havârna, Botoșani",
    "Helegiu, Bacău",
    "Heleșteni, Iași",
    "Hemeiuș, Bacău",
    "Herăști, Giurgiu",
    "Hereclean, Sălaj",
    "Hida, Sălaj",
    "Hidișelu de Sus, Bihor",
    "Hilișeu-Horia, Botoșani",
    "Hinova, Mehedinți",
    "Hlipiceni, Botoșani",
    "Hoceni, Vaslui",
    "Hodac, Mureș",
    "Hodod, Satu Mare",
    "Hodoșa, Mureș",
    "Hoghilag, Sibiu",
    "Hoghiz, Brașov",
    "Holbav, Brașov",
    "Holboca, Iași",
    "Holod, Bihor",
    "Homocea, Vrancea",
    "Homoroade, Satu Mare",
    "Homorod, Brașov",
    "Hopârta, Alba",
    "Horea, Alba",
    "Horgești, Bacău",
    "Horia, Constanța",
    "Horia, Neamț",
    "Horia, Tulcea",
    "Horlești, Iași",
    "Horoatu Crasnei, Sălaj",
    "Horodnic de Jos, Suceava",
    "Horodnic de Sus, Suceava",
    "Horodniceni, Suceava",
    "Hotarele, Giurgiu",
    "Hudești, Botoșani",
    "Hulubești, Dâmbovița",
    "Hurezani, Gorj",
    "Huruiești, Bacău",
    "Husasău de Tinca, Bihor",
    "Husnicioara, Mehedinți",
    "I.C. Brătianu, Tulcea",
    "I.L. Caragiale, Dâmbovița",
    "Iablanița, Caraș-Severin",
    "Iacobeni, Sibiu",
    "Iacobeni, Suceava",
    "Iana, Vaslui",
    "Ianca, Olt",
    "Iancu Jianu, Olt",
    "Iara, Cluj",
    "Iaslovăț, Suceava",
    "Ibănești, Botoșani",
    "Ibănești, Mureș",
    "Ibănești, Vaslui",
    "Iclănzel, Mureș",
    "Iclod, Cluj",
    "Icoana, Olt",
    "Icușești, Neamț",
    "Ideciu de Jos, Mureș",
    "Iecea Mare, Timiș",
    "Iedera, Dâmbovița",
    "Iepurești, Giurgiu",
    "Ieud, Maramureș",
    "Ighiu, Alba",
    "Ignești, Arad",
    "Ileana, Călărași",
    "Ileanda, Sălaj",
    "Ilia, Hunedoara",
    "Ilieni, Covasna",
    "Ilișești, Suceava",
    "Ilovăț, Mehedinți",
    "Ilovița, Mehedinți",
    "Ilva Mare, Bistrița-Năsăud",
    "Ilva Mică, Bistrița-Năsăud",
    "Independența, Călărași",
    "Independența, Constanța",
    "Independența, Galați",
    "Ineu, Bihor",
    "Întorsura, Dolj",
    "Întregalde, Alba",
    "Ion Corvin, Constanța",
    "Ion Creangă, Neamț",
    "Ion Neculce, Iași",
    "Ion Roată, Ialomița",
    "Ionești, Gorj",
    "Ionești, Vâlcea",
    "Iordăcheanu, Prahova",
    "Ip, Sălaj",
    "Ipatele, Iași",
    "Ipotești, Olt",
    "Ipotești, Suceava",
    "Iratoșu, Arad",
    "Ișalnița, Dolj",
    "Islaz, Teleorman",
    "Istria, Constanța",
    "Isverna, Mehedinți",
    "Isvoarele, Giurgiu",
    "Itești, Bacău",
    "Ivănești, Vaslui",
    "Ivești, Galați",
    "Ivești, Vaslui",
    "Izbiceni, Olt",
    "Izvoare, Dolj",
    "Izvoarele Sucevei, Suceava",
    "Izvoarele, Giurgiu",
    "Izvoarele, Olt",
    "Izvoarele, Prahova",
    "Izvoarele, Teleorman",
    "Izvoarele, Tulcea",
    "Izvoru Bârzii, Mehedinți",
    "Izvoru Berheciului, Bacău",
    "Izvoru Crișului, Cluj",
    "Izvoru, Argeș",
    "Jamu Mare, Timiș",
    "Jariștea, Vrancea",
    "Jebel, Timiș",
    "Jegălia, Călărași",
    "Jiana, Mehedinți",
    "Jibert, Brașov",
    "Jichișu de Jos, Cluj",
    "Jidvei, Alba",
    "Jijila, Tulcea",
    "Jilava, Ilfov",
    "Jilavele, Ialomița",
    "Jina, Sibiu",
    "Jirlău, Brăila",
    "Jitia, Vrancea",
    "Joița, Giurgiu",
    "Jorăști, Galați",
    "Joseni, Harghita",
    "Josenii Bârgăului, Bistrița-Năsăud",
    "Jucu, Cluj",
    "Jugureni, Prahova",
    "Jupânești, Gorj",
    "Jurilovca, Tulcea",
    "Lăcusteni, Vâlcea",
    "Lădești, Vâlcea",
    "Laloșu, Vâlcea",
    "Lapoș, Prahova",
    "Lăpugiu de Jos, Hunedoara",
    "Lăpuș, Maramureș",
    "Lăpușata, Vâlcea",
    "Lăpușnicel, Caraș-Severin",
    "Lăpușnicu Mare, Caraș-Severin",
    "Largu, Buzău",
    "Laslea, Sibiu",
    "Laza, Vaslui",
    "Lăzarea, Harghita",
    "Lăzăreni, Bihor",
    "Lazuri de Beiuș, Bihor",
    "Lazuri, Satu Mare",
    "Lechința, Bistrița-Năsăud",
    "Lehliu, Călărași",
    "Leleasca, Olt",
    "Lelese, Hunedoara",
    "Lelești, Gorj",
    "Leliceni, Harghita",
    "Lemnia, Covasna",
    "Lenauheim, Timiș",
    "Leorda, Botoșani",
    "Leordeni, Argeș",
    "Leordina, Maramureș",
    "Lerești, Argeș",
    "Lespezi, Iași",
    "Leșu, Bistrița-Năsăud",
    "Letca Nouă, Giurgiu",
    "Letca, Sălaj",
    "Lețcani, Iași",
    "Letea Veche, Bacău",
    "Leu, Dolj",
    "Licurici, Gorj",
    "Liebling, Timiș",
    "Liești, Galați",
    "Limanu, Constanța",
    "Lipănești, Prahova",
    "Lipnița, Constanța",
    "Lipova, Bacău",
    "Lipovăț, Vaslui",
    "Lipovu, Dolj",
    "Lisa, Brașov",
    "Lisa, Teleorman",
    "Lița, Teleorman",
    "Livada, Arad",
    "Livezeni, Mureș",
    "Livezi, Bacău",
    "Livezi, Vâlcea",
    "Livezile, Alba",
    "Livezile, Bistrița-Năsăud",
    "Livezile, Mehedinți",
    "Livezile, Timiș",
    "Loamneș, Sibiu",
    "Logrești, Gorj",
    "Lopadea Nouă, Alba",
    "Lopătari, Buzău",
    "Lovrin, Timiș",
    "Lozna, Botoșani",
    "Lozna, Sălaj",
    "Lucieni, Dâmbovița",
    "Luciu, Buzău",
    "Ludești, Dâmbovița",
    "Ludoș, Sibiu",
    "Lueta, Harghita",
    "Lugașu de Jos, Bihor",
    "Luica, Călărași",
    "Luizi-Călugăra, Bacău",
    "Lumina, Constanța",
    "Luna, Cluj",
    "Lunca Banului, Vaslui",
    "Lunca Bradului, Mureș",
    "Lunca Cernii de Jos, Hunedoara",
    "Lunca Corbului, Argeș",
    "Lunca Ilvei, Bistrița-Năsăud",
    "Lunca Mureșului, Alba",
    "Lunca de Jos, Harghita",
    "Lunca de Sus, Harghita",
    "Lunca, Bihor",
    "Lunca, Botoșani",
    "Lunca, Mureș",
    "Lunca, Teleorman",
    "Luncavița, Caraș-Severin",
    "Luncavița, Tulcea",
    "Luncoiu de Jos, Hunedoara",
    "Lungani, Iași",
    "Lungești, Vâlcea",
    "Lungulețu, Dâmbovița",
    "Lupac, Caraș-Severin",
    "Lupeni, Harghita",
    "Lupșa, Alba",
    "Lupșanu, Călărași",
    "Macea, Arad",
    "Măceșu de Jos, Dolj",
    "Măceșu de Sus, Dolj",
    "Măciuca, Vâlcea",
    "Mădăras, Bihor",
    "Mădăraș, Harghita",
    "Mădăraș, Mureș",
    "Mădârjac, Iași",
    "Mădulari, Vâlcea",
    "Măeriște, Sălaj",
    "Măgești, Bihor",
    "Măgherani, Mureș",
    "Măgirești, Bacău",
    "Maglavit, Dolj",
    "Măgura Ilvei, Bistrița-Năsăud",
    "Măgura, Bacău",
    "Măgura, Buzău",
    "Măgura, Teleorman",
    "Măgurele, Prahova",
    "Măgureni, Prahova",
    "Măguri-Răcătău, Cluj",
    "Mahmudia, Tulcea",
    "Maia, Ialomița",
    "Măicănești, Vrancea",
    "Maieru, Bistrița-Năsăud",
    "Măieruș, Brașov",
    "Malaia, Vâlcea",
    "Măldăeni, Teleorman",
    "Măldărești, Vâlcea",
    "Mălini, Suceava",
    "Maliuc, Tulcea",
    "Malnaș, Covasna",
    "Malovăț, Mehedinți",
    "Malu Mare, Dolj",
    "Malu cu Flori, Dâmbovița",
    "Malu, Giurgiu",
    "Mălureni, Argeș",
    "Mălușteni, Vaslui",
    "Manasia, Ialomița",
    "Mănăstirea Cașin, Bacău",
    "Mănăstirea Humorului, Suceava",
    "Mânăstirea, Călărași",
    "Mănăstireni, Cluj",
    "Mănăștiur, Timiș",
    "Mândra, Brașov",
    "Măneciu, Prahova",
    "Mănești, Dâmbovița",
    "Mănești, Prahova",
    "Manoleasa, Botoșani",
    "Mânzălești, Buzău",
    "Mărăcineni, Argeș",
    "Mărăcineni, Buzău",
    "Mărașu, Brăila",
    "Marca, Sălaj",
    "Mărculești, Ialomița",
    "Marga, Caraș-Severin",
    "Mărgăritești, Buzău",
    "Mărgău, Cluj",
    "Margina, Timiș",
    "Marginea, Suceava",
    "Mărgineni, Bacău",
    "Mărgineni, Neamț",
    "Mărișel, Cluj",
    "Mărișelu, Bistrița-Năsăud",
    "Marpod, Sibiu",
    "Mârșa, Giurgiu",
    "Mârșani, Dolj",
    "Mărtinești, Hunedoara",
    "Mărtiniș, Harghita",
    "Mărunței, Olt",
    "Mârzănești, Teleorman",
    "Mașloc, Timiș",
    "Măstăcani, Galați",
    "Mătăsari, Gorj",
    "Mătăsaru, Dâmbovița",
    "Matca, Galați",
    "Mateești, Vâlcea",
    "Matei, Bistrița-Năsăud",
    "Măureni, Caraș-Severin",
    "Mavrodin, Teleorman",
    "Măxineni, Brăila",
    "Medieșu Aurit, Satu Mare",
    "Mehadia, Caraș-Severin",
    "Mehadica, Caraș-Severin",
    "Melinești, Dolj",
    "Mera, Vrancea",
    "Merei, Buzău",
    "Mereni, Constanța",
    "Mereni, Covasna",
    "Mereni, Teleorman",
    "Merești, Harghita",
    "Merghindeal, Sibiu",
    "Merișani, Argeș",
    "Meseșenii de Jos, Sălaj",
    "Meteș, Alba",
    "Mica, Cluj",
    "Mica, Mureș",
    "Micăsasa, Sibiu",
    "Micești, Argeș",
    "Miceștii de Câmpie, Bistrița-Năsăud",
    "Micfalău, Covasna",
    "Miclești, Vaslui",
    "Micula, Satu Mare",
    "Mihăești, Argeș",
    "Mihăești, Olt",
    "Mihăești, Vâlcea",
    "Mihai Bravu, Giurgiu",
    "Mihai Bravu, Tulcea",
    "Mihai Eminescu, Botoșani",
    "Mihai Viteazu, Cluj",
    "Mihai Viteazu, Constanța",
    "Mihail Kogălniceanu, Constanța",
    "Mihail Kogălniceanu, Ialomița",
    "Mihail Kogălniceanu, Tulcea",
    "Mihăileni, Botoșani",
    "Mihăileni, Harghita",
    "Mihăileni, Sibiu",
    "Mihăilești, Buzău",
    "Mihălășeni, Botoșani",
    "Mihalț, Alba",
    "Miheșu de Câmpie, Mureș",
    "Milaș, Bistrița-Năsăud",
    "Milcoiu, Vâlcea",
    "Milcov, Olt",
    "Milcovul, Vrancea",
    "Mileanca, Botoșani",
    "Miloșești, Ialomița",
    "Mintiu Gherlii, Cluj",
    "Mioarele, Argeș",
    "Mirăslău, Alba",
    "Mircea Vodă, Brăila",
    "Mircea Vodă, Constanța",
    "Mircești, Iași",
    "Mireșu Mare, Maramureș",
    "Mironeasa, Iași",
    "Miroși, Argeș",
    "Miroslava, Iași",
    "Miroslovești, Iași",
    "Mirșid, Sălaj",
    "Mișca, Arad",
    "Mischii, Dolj",
    "Mitoc, Botoșani",
    "Mitocu Dragomirnei, Suceava",
    "Mitreni, Călărași",
    "Mitrofani, Vâlcea",
    "Moacșa, Covasna",
    "Moara Vlăsiei, Ilfov",
    "Moara, Suceava",
    "Mociu, Cluj",
    "Modelu, Călărași",
    "Moftin, Satu Mare",
    "Mogoș, Alba",
    "Mogoșani, Dâmbovița",
    "Mogoșești, Iași",
    "Mogoșești-Siret, Iași",
    "Mogoșoaia, Ilfov",
    "Moieciu, Brașov",
    "Moisei, Maramureș",
    "Moldova-Sulița, Suceava",
    "Moldovenești, Cluj",
    "Moldoveni, Ialomița",
    "Moldoveni, Neamț",
    "Moldovița, Suceava",
    "Moneasa, Arad",
    "Monor, Bistrița-Năsăud",
    "Morărești, Argeș",
    "Moravița, Timiș",
    "Moroeni, Dâmbovița",
    "Morteni, Dâmbovița",
    "Morunglav, Olt",
    "Moșna, Iași",
    "Moșna, Sibiu",
    "Moșnița Nouă, Timiș",
    "Moșoaia, Argeș",
    "Moșteni, Teleorman",
    "Moțăieni, Dâmbovița",
    "Moțăței, Dolj",
    "Moțca, Iași",
    "Motoșeni, Bacău",
    "Movila Banului, Buzău",
    "Movila Miresii, Brăila",
    "Movila, Ialomița",
    "Movileni, Galați",
    "Movileni, Iași",
    "Movileni, Olt",
    "Movilița, Ialomița",
    "Movilița, Vrancea",
    "Mozăceni, Argeș",
    "Muereasca, Vâlcea",
    "Mugeni, Harghita",
    "Munteni, Galați",
    "Munteni-Buzău, Ialomița",
    "Muntenii de Jos, Vaslui",
    "Muntenii de Sus, Vaslui",
    "Murgași, Dolj",
    "Murgești, Buzău",
    "Murighiol, Tulcea",
    "Mușătești, Argeș",
    "Mușenița, Suceava",
    "Mușetești, Gorj",
    "Nadeș, Mureș",
    "Nădrag, Timiș",
    "Năeni, Buzău",
    "Naidăș, Caraș-Severin",
    "Nalbant, Tulcea",
    "Nămoloasa, Galați",
    "Nana, Călărași",
    "Nănești, Vrancea",
    "Nanov, Teleorman",
    "Năpradea, Sălaj",
    "Năruja, Vrancea",
    "Năsturelu, Teleorman",
    "Neaua, Mureș",
    "Necșești, Teleorman",
    "Negoi, Dolj",
    "Negomir, Gorj",
    "Negrași, Argeș",
    "Negreni, Cluj",
    "Negrești, Neamț",
    "Negri, Bacău",
    "Negrilești, Bistrița-Năsăud",
    "Negrilești, Galați",
    "Negrilești, Vrancea",
    "Nenciulești, Teleorman",
    "Nereju, Vrancea",
    "Nicolae Bălcescu, Bacău",
    "Nicolae Bălcescu, Călărași",
    "Nicolae Bălcescu, Constanța",
    "Nicolae Bălcescu, Vâlcea",
    "Nicolae Titulescu, Olt",
    "Nicorești, Galați",
    "Nicșeni, Botoșani",
    "Niculești, Dâmbovița",
    "Niculițel, Tulcea",
    "Nimigea, Bistrița-Năsăud",
    "Nistorești, Vrancea",
    "Nițchidorf, Timiș",
    "Nocrich, Sibiu",
    "Nojorid, Bihor",
    "Noșlac, Alba",
    "Nucet, Dâmbovița",
    "Nuci, Ilfov",
    "Nucșoara, Argeș",
    "Nufăru, Tulcea",
    "Nușeni, Bistrița-Năsăud",
    "Nușfalău, Sălaj",
    "Oancea, Galați",
    "Oarja, Argeș",
    "Oarța de Jos, Maramureș",
    "Obârșia de Câmp, Mehedinți",
    "Obârșia, Olt",
    "Obârșia-Cloșani, Mehedinți",
    "Oboga, Olt",
    "Obreja, Caraș-Severin",
    "Obrejița, Vrancea",
    "Ocland, Harghita",
    "Ocna Șugatag, Maramureș",
    "Ocna de Fier, Caraș-Severin",
    "Ocnița, Dâmbovița",
    "Ocoliș, Alba",
    "Odăile, Buzău",
    "Odobești, Bacău",
    "Odobești, Dâmbovița",
    "Odoreu, Satu Mare",
    "Ogra, Mureș",
    "Ograda, Ialomița",
    "Ogrezeni, Giurgiu",
    "Ohaba Lungă, Timiș",
    "Ohaba, Alba",
    "Oinacu, Giurgiu",
    "Oituz, Bacău",
    "Ojdula, Covasna",
    "Olanu, Vâlcea",
    "Olari, Arad",
    "Olari, Prahova",
    "Olcea, Bihor",
    "Oltenești, Vaslui",
    "Olteni, Teleorman",
    "Oltina, Constanța",
    "Oncești, Bacău",
    "Oncești, Maramureș",
    "Oniceni, Neamț",
    "Oporelu, Olt",
    "Oprișor, Mehedinți",
    "Optași-Măgura, Olt",
    "Orăștioara de Sus, Hunedoara",
    "Orașu Nou, Satu Mare",
    "Orbeasca, Teleorman",
    "Orbeni, Bacău",
    "Orlat, Sibiu",
    "Orlea, Olt",
    "Orlești, Vâlcea",
    "Ormeniș, Brașov",
    "Orodel, Dolj",
    "Orțișoara, Timiș",
    "Oșești, Vaslui",
    "Osica de Jos, Olt",
    "Osica de Sus, Olt",
    "Oșorhei, Bihor",
    "Ostra, Suceava",
    "Ostrov, Constanța",
    "Ostrov, Tulcea",
    "Ostroveni, Dolj",
    "Otelec, Timiș",
    "Oțeleni, Iași",
    "Oteșani, Vâlcea",
    "Ozun, Covasna",
    "Păcureți, Prahova",
    "Padeș, Gorj",
    "Padina, Buzău",
    "Pădina, Mehedinți",
    "Pădureni, Timiș",
    "Pădureni, Vaslui",
    "Palanca, Bacău",
    "Pălatca, Cluj",
    "Paleu, Bihor",
    "Paltin, Vrancea",
    "Păltiniș, Botoșani",
    "Păltiniș, Caraș-Severin",
    "Păltinoasa, Suceava",
    "Panaci, Suceava",
    "Pănătău, Buzău",
    "Pâncești, Bacău",
    "Pâncești, Neamț",
    "Pănet, Mureș",
    "Pângărați, Neamț",
    "Pantelimon, Constanța",
    "Panticeu, Cluj",
    "Papiu Ilarian, Mureș",
    "Părău, Brașov",
    "Parava, Bacău",
    "Pardina, Tulcea",
    "Pardoși, Buzău",
    "Pârgărești, Bacău",
    "Parincea, Bacău",
    "Pârjol, Bacău",
    "Pârscov, Buzău",
    "Pârșcoveni, Olt",
    "Parța, Timiș",
    "Pârteștii de Jos, Suceava",
    "Parva, Bistrița-Năsăud",
    "Păsăreni, Mureș",
    "Păstrăveni, Neamț",
    "Pătrăuți, Suceava",
    "Pătulele, Mehedinți",
    "Păuca, Sibiu",
    "Păuleni-Ciuc, Harghita",
    "Păulești, Prahova",
    "Păulești, Satu Mare",
    "Păulești, Vrancea",
    "Păuliș, Arad",
    "Păunești, Vrancea",
    "Păușești, Vâlcea",
    "Păușești-Măglași, Vâlcea",
    "Peceneaga, Tulcea",
    "Pechea, Galați",
    "Pecineaga, Constanța",
    "Peciu Nou, Timiș",
    "Peregu Mare, Arad",
    "Peretu, Teleorman",
    "Periam, Timiș",
    "Pericei, Sălaj",
    "Perieni, Vaslui",
    "Perieți, Ialomița",
    "Perieți, Olt",
    "Periș, Ilfov",
    "Perișani, Vâlcea",
    "Perișor, Dolj",
    "Perișoru, Călărași",
    "Perșinari, Dâmbovița",
    "Pesac, Timiș",
    "Pesceana, Vâlcea",
    "Peștera, Constanța",
    "Peștișani, Gorj",
    "Pestișu Mic, Hunedoara",
    "Petelea, Mureș",
    "Petrăchioaia, Ilfov",
    "Petrești, Dâmbovița",
    "Petrești, Satu Mare",
    "Petreștii de Jos, Cluj",
    "Petricani, Neamț",
    "Petriș, Arad",
    "Petrova, Maramureș",
    "Petru Rareș, Bistrița-Năsăud",
    "Pianu, Alba",
    "Piatra Șoimului, Neamț",
    "Piatra, Teleorman",
    "Pielești, Dolj",
    "Pietrari, Dâmbovița",
    "Pietrari, Vâlcea",
    "Pietroasa, Bihor",
    "Pietroasa, Timiș",
    "Pietroasele, Buzău",
    "Pietroșani, Argeș",
    "Pietroșani, Teleorman",
    "Pietroșița, Dâmbovița",
    "Pilu, Arad",
    "Pipirig, Neamț",
    "Pir, Satu Mare",
    "Pișchia, Timiș",
    "Pișcolt, Satu Mare",
    "Piscu Vechi, Dolj",
    "Piscu, Galați",
    "Plăieșii de Jos, Harghita",
    "Plătărești, Călărași",
    "Platonești, Ialomița",
    "Plenița, Dolj",
    "Pleșcuța, Arad",
    "Pleșoi, Dolj",
    "Pleșoiu, Olt",
    "Plopana, Bacău",
    "Plopii-Slăvitești, Teleorman",
    "Plopiș, Sălaj",
    "Plopșoru, Gorj",
    "Plopu, Prahova",
    "Plosca, Teleorman",
    "Ploscoș, Cluj",
    "Ploscuțeni, Vrancea",
    "Plugari, Iași",
    "Poarta Albă, Constanța",
    "Poboru, Olt",
    "Pochidia, Vaslui",
    "Pocola, Bihor",
    "Podari, Dolj",
    "Podeni, Mehedinți",
    "Podenii Noi, Prahova",
    "Podgoria, Buzău",
    "Podoleni, Neamț",
    "Podu Turcului, Bacău",
    "Poduri, Bacău",
    "Poeni, Teleorman",
    "Pogăceaua, Mureș",
    "Pogana, Vaslui",
    "Pogonești, Vaslui",
    "Poian, Covasna",
    "Poiana Blenchii, Sălaj",
    "Poiana Câmpina, Prahova",
    "Poiana Cristei, Vrancea",
    "Poiana Ilvei, Bistrița-Năsăud",
    "Poiana Lacului, Argeș",
    "Poiana Mare, Dolj",
    "Poiana Mărului, Brașov",
    "Poiana Sibiului, Sibiu",
    "Poiana Stampei, Suceava",
    "Poiana Teiului, Neamț",
    "Poiana Vadului, Alba",
    "Poiana, Dâmbovița",
    "Poiana, Galați",
    "Poienari, Neamț",
    "Poienarii Burchii, Prahova",
    "Poienarii de Argeș, Argeș",
    "Poienarii de Muscel, Argeș",
    "Poienești, Vaslui",
    "Poieni, Cluj",
    "Poieni-Solca, Suceava",
    "Poienile Izei, Maramureș",
    "Poienile de sub Munte, Maramureș",
    "Pojejena, Caraș-Severin",
    "Pojorâta, Suceava",
    "Polovragi, Gorj",
    "Pomârla, Botoșani",
    "Pomezeu, Bihor",
    "Pomi, Satu Mare",
    "Ponoarele, Mehedinți",
    "Ponor, Alba",
    "Popești, Argeș",
    "Popești, Bihor",
    "Popești, Iași",
    "Popești, Vâlcea",
    "Popești, Vrancea",
    "Poplaca, Sibiu",
    "Popricani, Iași",
    "Poroina Mare, Mehedinți",
    "Poroschia, Teleorman",
    "Porumbacu de Jos, Sibiu",
    "Porumbeni, Harghita",
    "Porumbești, Satu Mare",
    "Poșaga, Alba",
    "Posești, Prahova",
    "Poșta Câlnău, Buzău",
    "Potlogi, Dâmbovița",
    "Praid, Harghita",
    "Prăjeni, Botoșani",
    "Prăjești, Bacău",
    "Predeal-Sărari, Prahova",
    "Predești, Dolj",
    "Prejmer, Brașov",
    "Preutești, Suceava",
    "Priboieni, Argeș",
    "Prigor, Caraș-Severin",
    "Prigoria, Gorj",
    "Priponești, Galați",
    "Prisăcani, Iași",
    "Priseaca, Olt",
    "Pristol, Mehedinți",
    "Probota, Iași",
    "Produlești, Dâmbovița",
    "Provița de Jos, Prahova",
    "Provița de Sus, Prahova",
    "Prundeni, Vâlcea",
    "Prundu Bârgăului, Bistrița-Năsăud",
    "Prundu, Giurgiu",
    "Prunișor, Mehedinți",
    "Pucheni, Dâmbovița",
    "Puchenii Mari, Prahova",
    "Pufești, Vrancea",
    "Pui, Hunedoara",
    "Puiești, Buzău",
    "Puiești, Vaslui",
    "Pungești, Vaslui",
    "Punghina, Mehedinți",
    "Purani, Teleorman",
    "Pușcași, Vaslui",
    "Putineiu, Giurgiu",
    "Putineiu, Teleorman",
    "Putna, Suceava",
    "Răbăgani, Bihor",
    "Râca, Argeș",
    "Răcăciuni, Bacău",
    "Răcășdia, Caraș-Severin",
    "Răchiteni, Iași",
    "Răchiți, Botoșani",
    "Răchitoasa, Bacău",
    "Răchitova, Hunedoara",
    "Raciu, Dâmbovița",
    "Râciu, Mureș",
    "Răcoasa, Vrancea",
    "Racoș, Brașov",
    "Racova, Bacău",
    "Racovița, Brăila",
    "Racovița, Sibiu",
    "Racovița, Timiș",
    "Racovița, Vâlcea",
    "Racovițeni, Buzău",
    "Racșa, Satu Mare",
    "Racu, Harghita",
    "Rădășeni, Suceava",
    "Rădăuți-Prut, Botoșani",
    "Rădești, Alba",
    "Rădești, Galați",
    "Rădoiești, Teleorman",
    "Radomirești, Olt",
    "Radovan, Dolj",
    "Radovanu, Călărași",
    "Răducăneni, Iași",
    "Rădulești, Ialomița",
    "Rafaila, Vaslui",
    "Râfov, Prahova",
    "Râmeț, Alba",
    "Ramna, Caraș-Severin",
    "Râmnicelu, Brăila",
    "Râmnicelu, Buzău",
    "Rapoltu Mare, Hunedoara",
    "Râșca, Suceava",
    "Răscăeți, Dâmbovița",
    "Rășinari, Sibiu",
    "Răsmirești, Teleorman",
    "Rasova, Constanța",
    "Rast, Dolj",
    "Răstoaca, Vrancea",
    "Răstolița, Mureș",
    "Răsuceni, Giurgiu",
    "Rătești, Argeș",
    "Râu Alb, Dâmbovița",
    "Râu Sadului, Sibiu",
    "Râu de Mori, Hunedoara",
    "Răucești, Neamț",
    "Răuseni, Botoșani",
    "Războieni, Neamț",
    "Răzvad, Dâmbovița",
    "Rebra, Bistrița-Năsăud",
    "Rebricea, Vaslui",
    "Rebrișoara, Bistrița-Năsăud",
    "Recea, Argeș",
    "Recea, Brașov",
    "Recea, Maramureș",
    "Recea-Cristur, Cluj",
    "Reci, Covasna",
    "Redea, Olt",
    "Rediu, Galați",
    "Rediu, Iași",
    "Rediu, Neamț",
    "Reghiu, Vrancea",
    "Remetea Chioarului, Maramureș",
    "Remetea Mare, Timiș",
    "Remetea, Bihor",
    "Remetea, Harghita",
    "Remeți, Maramureș",
    "Repedea, Maramureș",
    "Reviga, Ialomița",
    "Ribița, Hunedoara",
    "Rieni, Bihor",
    "Rimetea, Alba",
    "Ripiceni, Botoșani",
    "Rișca, Cluj",
    "Roata de Jos, Giurgiu",
    "Robănești, Dolj",
    "Robeasca, Buzău",
    "Rociu, Argeș",
    "Rodna, Bistrița-Năsăud",
    "Roești, Vâlcea",
    "Rogova, Mehedinți",
    "Rojiște, Dolj",
    "Roma, Botoșani",
    "Românași, Sălaj",
    "Românești, Botoșani",
    "Românești, Iași",
    "Români, Neamț",
    "Romanu, Brăila",
    "Romos, Hunedoara",
    "Romuli, Bistrița-Năsăud",
    "Rona de Jos, Maramureș",
    "Rona de Sus, Maramureș",
    "Roșcani, Iași",
    "Roseți, Călărași",
    "Roșia Montană, Alba",
    "Roșia de Amaradia, Gorj",
    "Roșia de Secaș, Alba",
    "Roșia, Bihor",
    "Roșia, Sibiu",
    "Roșiești, Vaslui",
    "Roșiile, Vâlcea",
    "Roșiori, Bacău",
    "Roșiori, Bihor",
    "Roșiori, Brăila",
    "Roșiori, Ialomița",
    "Rotunda, Olt",
    "Rozavlea, Maramureș",
    "Rucăr, Argeș",
    "Ruginești, Vrancea",
    "Ruginoasa, Iași",
    "Ruginoasa, Neamț",
    "Runcu Salvei, Bistrița-Năsăud",
    "Runcu, Dâmbovița",
    "Runcu, Gorj",
    "Runcu, Vâlcea",
    "Rus, Sălaj",
    "Rusănești, Olt",
    "Rusca Montană, Caraș-Severin",
    "Ruscova, Maramureș",
    "Rușețu, Buzău",
    "Rușii-Munți, Mureș",
    "Săbăoani, Neamț",
    "Săbăreni, Giurgiu",
    "Săcădat, Bihor",
    "Săcălășeni, Maramureș",
    "Săcălaz, Timiș",
    "Săcășeni, Satu Mare",
    "Săcel, Harghita",
    "Săcel, Maramureș",
    "Săcele, Constanța",
    "Săcelu, Gorj",
    "Săceni, Teleorman",
    "Sacoșu Turcesc, Timiș",
    "Sacu, Caraș-Severin",
    "Săcuieu, Cluj",
    "Sadova, Dolj",
    "Sadova, Suceava",
    "Sadu, Sibiu",
    "Saelele, Teleorman",
    "Sâg, Sălaj",
    "Șag, Timiș",
    "Săgeata, Buzău",
    "Sagna, Neamț",
    "Șagu, Arad",
    "Săhăteni, Buzău",
    "Sălacea, Bihor",
    "Sălard, Bihor",
    "Sălașu de Sus, Hunedoara",
    "Sălățig, Sălaj",
    "Sălătrucel, Vâlcea",
    "Sălătrucu, Argeș",
    "Salcia Tudor, Brăila",
    "Salcia, Mehedinți",
    "Salcia, Prahova",
    "Salcia, Teleorman",
    "Sălciile, Prahova",
    "Sălcioara, Dâmbovița",
    "Sălcioara, Ialomița",
    "Sălciua, Alba",
    "Sălcuța, Dolj",
    "Saligny, Constanța",
    "Săliștea, Alba",
    "Sălsig, Maramureș",
    "Salva, Bistrița-Năsăud",
    "Samarinești, Gorj",
    "Sâmbăta de Sus, Brașov",
    "Sâmbăta, Bihor",
    "Sâmburești, Olt",
    "Șamșud, Sălaj",
    "Sânandrei, Timiș",
    "Sâncel, Alba",
    "Sâncrăieni, Harghita",
    "Sâncraiu de Mureș, Mureș",
    "Sâncraiu, Cluj",
    "Sândominic, Harghita",
    "Șandra, Timiș",
    "Sănduleni, Bacău",
    "Săndulești, Cluj",
    "Sângeorgiu de Mureș, Mureș",
    "Sânger, Mureș",
    "Sângeru, Prahova",
    "Sâniob, Bihor",
    "Sanislău, Satu Mare",
    "Sânmartin, Bihor",
    "Sânmărtin, Cluj",
    "Sânmartin, Harghita",
    "Sânmihaiu Almașului, Sălaj",
    "Sânmihaiu Român, Timiș",
    "Sânmihaiu de Câmpie, Bistrița-Năsăud",
    "Sânnicolau Român, Bihor",
    "Sânpaul, Cluj",
    "Sânpaul, Mureș",
    "Sânpetru Mare, Timiș",
    "Sânpetru de Câmpie, Mureș",
    "Sânpetru, Brașov",
    "Sânsimion, Harghita",
    "Șanț, Bistrița-Năsăud",
    "Santa Mare, Botoșani",
    "Sântămăria-Orlea, Hunedoara",
    "Sântana de Mureș, Mureș",
    "Sântandrei, Bihor",
    "Santău, Satu Mare",
    "Sântimbru, Alba",
    "Sântimbru, Harghita",
    "Sânzieni, Covasna",
    "Săpânța, Maramureș",
    "Săpata, Argeș",
    "Săpoca, Buzău",
    "Saraiu, Constanța",
    "Sarasău, Maramureș",
    "Sărata, Bacău",
    "Sărățeni, Ialomița",
    "Sărățeni, Mureș",
    "Saravale, Timiș",
    "Sârbeni, Teleorman",
    "Sârbi, Bihor",
    "Sârbii-Măgura, Olt",
    "Sarichioi, Tulcea",
    "Sărmaș, Harghita",
    "Sărmășag, Sălaj",
    "Sarmizegetusa, Hunedoara",
    "Șaru Dornei, Suceava",
    "Sărulești, Buzău",
    "Sărulești, Călărași",
    "Sasca Montană, Caraș-Severin",
    "Saschiz, Mureș",
    "Săsciori, Alba",
    "Sascut, Bacău",
    "Satchinez, Timiș",
    "Satu Mare, Harghita",
    "Satu Mare, Suceava",
    "Satulung, Maramureș",
    "Săuca, Satu Mare",
    "Săucești, Bacău",
    "Săulești, Gorj",
    "Șăulia, Mureș",
    "Săvădisla, Cluj",
    "Săvârșin, Arad",
    "Săveni, Ialomița",
    "Săvinești, Neamț",
    "Scăești, Dolj",
    "Scânteia, Ialomița",
    "Scânteia, Iași",
    "Scânteiești, Galați",
    "Scărișoara, Alba",
    "Scărișoara, Olt",
    "Șcheia, Iași",
    "Șcheia, Suceava",
    "Schela, Galați",
    "Schela, Gorj",
    "Schitu Duca, Iași",
    "Schitu Golești, Argeș",
    "Schitu, Giurgiu",
    "Schitu, Olt",
    "Scoarța, Gorj",
    "Scobinți, Iași",
    "Scorțaru Nou, Brăila",
    "Scorțeni, Bacău",
    "Scorțeni, Prahova",
    "Scorțoasa, Buzău",
    "Scrioaștea, Teleorman",
    "Scundu, Vâlcea",
    "Scurtu Mare, Teleorman",
    "Scutelnici, Buzău",
    "Seaca de Câmp, Dolj",
    "Seaca de Pădure, Dolj",
    "Seaca, Olt",
    "Seaca, Teleorman",
    "Secăria, Prahova",
    "Secaș, Timiș",
    "Secu, Dolj",
    "Secuieni, Bacău",
    "Secuieni, Harghita",
    "Secuieni, Neamț",
    "Secusigiu, Arad",
    "Segarcea-Vale, Teleorman",
    "Șeica Mare, Sibiu",
    "Șeica Mică, Sibiu",
    "Seimeni, Constanța",
    "Șeitin, Arad",
    "Șelaru, Dâmbovița",
    "Seleuș, Arad",
    "Șelimbăr, Sibiu",
    "Semlac, Arad",
    "Șendreni, Galați",
    "Șendriceni, Botoșani",
    "Șepreuș, Arad",
    "Șerbănești, Olt",
    "Șerbăuți, Suceava",
    "Șercaia, Brașov",
    "Sfântu Gheorghe, Ialomița",
    "Sfântu Gheorghe, Tulcea",
    "Sfințești, Teleorman",
    "Șibot, Alba",
    "Sic, Cluj",
    "Sichevița, Caraș-Severin",
    "Șicula, Arad",
    "Siculeni, Harghita",
    "Șieu, Bistrița-Năsăud",
    "Șieu, Maramureș",
    "Șieu-Măgheruș, Bistrița-Năsăud",
    "Șieu-Odorhei, Bistrița-Năsăud",
    "Șieuț, Bistrița-Năsăud",
    "Sihlea, Vrancea",
    "Șilindia, Arad",
    "Siliștea Crucii, Dolj",
    "Siliștea Gumești, Teleorman",
    "Siliștea, Brăila",
    "Siliștea, Constanța",
    "Siliștea, Teleorman",
    "Silivașu de Câmpie, Bistrița-Năsăud",
    "Șimand, Arad",
    "Șimian, Bihor",
    "Șimian, Mehedinți",
    "Siminicea, Suceava",
    "Șimișna, Sălaj",
    "Șimnicu de Sus, Dolj",
    "Șimonești, Harghita",
    "Șinca Nouă, Brașov",
    "Șinca, Brașov",
    "Șincai, Mureș",
    "Sinești, Ialomița",
    "Sinești, Iași",
    "Sinești, Vâlcea",
    "Singureni, Giurgiu",
    "Sintea Mare, Arad",
    "Șintereag, Bistrița-Năsăud",
    "Șinteu, Bihor",
    "Șipote, Iași",
    "Sirețel, Iași",
    "Șiria, Arad",
    "Șirineasa, Vâlcea",
    "Siriu, Buzău",
    "Șirna, Prahova",
    "Șișești, Maramureș",
    "Șișești, Mehedinți",
    "Șiștarovăț, Arad",
    "Sita Buzăului, Covasna",
    "Slatina, Suceava",
    "Slatina-Timiș, Caraș-Severin",
    "Slătioara, Olt",
    "Slătioara, Vâlcea",
    "Slava Cercheză, Tulcea",
    "Slimnic, Sibiu",
    "Slivilești, Gorj",
    "Slobozia Bradului, Vrancea",
    "Slobozia Ciorăști, Vrancea",
    "Slobozia Conachi, Galați",
    "Slobozia Mândra, Teleorman",
    "Slobozia Moară, Dâmbovița",
    "Slobozia, Argeș",
    "Slobozia, Giurgiu",
    "Smârdan, Galați",
    "Smârdan, Tulcea",
    "Smârdioasa, Teleorman",
    "Smeeni, Buzău",
    "Smulți, Galați",
    "Snagov, Ilfov",
    "Șoarș, Brașov",
    "Socodor, Arad",
    "Socol, Caraș-Severin",
    "Socond, Satu Mare",
    "Șofronea, Arad",
    "Sohatu, Călărași",
    "Sohodol, Alba",
    "Șoimari, Prahova",
    "Șoimi, Bihor",
    "Șoimuș, Hunedoara",
    "Șoldanu, Călărași",
    "Solești, Vaslui",
    "Solonț, Bacău",
    "Solovăstru, Mureș",
    "Someș-Odorhei, Sălaj",
    "Somova, Tulcea",
    "Șona, Alba",
    "Șopârlița, Olt",
    "Sopot, Dolj",
    "Șopotu Nou, Caraș-Severin",
    "Șotânga, Dâmbovița",
    "Șotrile, Prahova",
    "Șovarna, Mehedinți",
    "Soveja, Vrancea",
    "Spanțov, Călărași",
    "Spermezeu, Bistrița-Năsăud",
    "Spineni, Olt",
    "Spinuș, Bihor",
    "Sprâncenata, Olt",
    "Șpring, Alba",
    "Spulber, Vrancea",
    "Stâlpeni, Argeș",
    "Stâlpu, Buzău",
    "Stânceni, Mureș",
    "Stăncuța, Brăila",
    "Stănești, Giurgiu",
    "Stănești, Gorj",
    "Stănești, Vâlcea",
    "Stângăceaua, Mehedinți",
    "Stănilești, Vaslui",
    "Stănișești, Bacău",
    "Stănița, Neamț",
    "Starchiojd, Prahova",
    "Stăuceni, Botoșani",
    "Ștefan Vodă, Călărași",
    "Ștefan cel Mare, Argeș",
    "Ștefan cel Mare, Bacău",
    "Ștefan cel Mare, Călărași",
    "Ștefan cel Mare, Neamț",
    "Ștefan cel Mare, Olt",
    "Ștefan cel Mare, Vaslui",
    "Ștefănești, Vâlcea",
    "Ștefăneștii de Jos, Ilfov",
    "Ștefești, Prahova",
    "Stejari, Gorj",
    "Stejaru, Teleorman",
    "Stejaru, Tulcea",
    "Stelnica, Ialomița",
    "Știubieni, Botoșani",
    "Știuca, Timiș",
    "Stoenești, Argeș",
    "Stoenești, Giurgiu",
    "Stoenești, Olt",
    "Stoenești, Vâlcea",
    "Stoicănești, Olt",
    "Stoilești, Vâlcea",
    "Stoina, Gorj",
    "Stolniceni-Prăjescu, Iași",
    "Stolnici, Argeș",
    "Ștorobăneasa, Teleorman",
    "Straja, Suceava",
    "Strâmtura, Maramureș",
    "Străoane, Vrancea",
    "Strejești, Olt",
    "Stremț, Alba",
    "Stroești, Vâlcea",
    "Stroiești, Suceava",
    "Strugari, Bacău",
    "Strunga, Iași",
    "Studina, Olt",
    "Stulpicani, Suceava",
    "Suatu, Cluj",
    "Subcetate, Harghita",
    "Suceveni, Galați",
    "Sucevița, Suceava",
    "Suciu de Sus, Maramureș",
    "Sudiți, Ialomița",
    "Șugag, Alba",
    "Suhaia, Teleorman",
    "Suharău, Botoșani",
    "Suhurlui, Galați",
    "Șuici, Argeș",
    "Șuletea, Vaslui",
    "Sulița, Botoșani",
    "Șuncuiuș, Bihor",
    "Suplac, Mureș",
    "Suplacu de Barcău, Bihor",
    "Supur, Satu Mare",
    "Șura Mare, Sibiu",
    "Șura Mică, Sibiu",
    "Suraia, Vrancea",
    "Surani, Prahova",
    "Surdila-Găiseanca, Brăila",
    "Surdila-Greci, Brăila",
    "Surduc, Sălaj",
    "Șușani, Vâlcea",
    "Suseni, Argeș",
    "Suseni, Harghita",
    "Suseni, Mureș",
    "Șuțești, Brăila",
    "Sutești, Vâlcea",
    "Svinița, Mehedinți",
    "Tăcuta, Vaslui",
    "Țaga, Cluj",
    "Talea, Prahova",
    "Talpa, Teleorman",
    "Tălpaș, Dolj",
    "Tămădău Mare, Călărași",
    "Tămășeni, Neamț",
    "Tămășeu, Bihor",
    "Tamași, Bacău",
    "Tâmboești, Vrancea",
    "Tâmna, Mehedinți",
    "Tanacu, Vaslui",
    "Tănăsoaia, Vrancea",
    "Tansa, Iași",
    "Țânțăreni, Gorj",
    "Tărcaia, Bihor",
    "Tarcău, Neamț",
    "Tarcea, Bihor",
    "Târgșoru Vechi, Prahova",
    "Târgu Trotuș, Bacău",
    "Târgușor, Constanța",
    "Târlișua, Bistrița-Năsăud",
    "Tărlungeni, Brașov",
    "Tarna Mare, Satu Mare",
    "Târnava, Sibiu",
    "Târnova, Arad",
    "Târnova, Caraș-Severin",
    "Târșolț, Satu Mare",
    "Tărtășești, Dâmbovița",
    "Tașca, Neamț",
    "Tătărani, Dâmbovița",
    "Tătărăni, Vaslui",
    "Tătăranu, Vrancea",
    "Tătărăști, Bacău",
    "Tătărăștii de Jos, Teleorman",
    "Tătărăștii de Sus, Teleorman",
    "Tătaru, Prahova",
    "Tătăruși, Iași",
    "Tătulești, Olt",
    "Tăureni, Mureș",
    "Tauț, Arad",
    "Tăuteu, Bihor",
    "Tazlău, Neamț",
    "Teaca, Bistrița-Năsăud",
    "Teasc, Dolj",
    "Teișani, Prahova",
    "Teiu, Argeș",
    "Telciu, Bistrița-Năsăud",
    "Telega, Prahova",
    "Telești, Gorj",
    "Teliu, Brașov",
    "Teliucu Inferior, Hunedoara",
    "Țepu, Galați",
    "Terebești, Satu Mare",
    "Teregova, Caraș-Severin",
    "Teremia Mare, Timiș",
    "Terpezița, Dolj",
    "Teslui, Dolj",
    "Teslui, Olt",
    "Țețchea, Bihor",
    "Tetoiu, Vâlcea",
    "Tia Mare, Olt",
    "Țibana, Iași",
    "Țibănești, Iași",
    "Țibucani, Neamț",
    "Tichilești, Brăila",
    "Ticuș, Brașov",
    "Ticvaniu Mare, Caraș-Severin",
    "Țifești, Vrancea",
    "Țigănași, Iași",
    "Țigănești, Teleorman",
    "Tigveni, Argeș",
    "Tiha Bârgăului, Bistrița-Năsăud",
    "Tileagd, Bihor",
    "Tilișca, Sibiu",
    "Timișești, Neamț",
    "Tinca, Bihor",
    "Tinosu, Prahova",
    "Țintești, Buzău",
    "Tiream, Satu Mare",
    "Tisău, Buzău",
    "Țițești, Argeș",
    "Titești, Vâlcea",
    "Toboliu, Bihor",
    "Todireni, Botoșani",
    "Todirești, Iași",
    "Todirești, Suceava",
    "Todirești, Vaslui",
    "Tomești, Harghita",
    "Tomești, Hunedoara",
    "Tomești, Iași",
    "Tomești, Timiș",
    "Tomnatic, Timiș",
    "Tomșani, Prahova",
    "Tomșani, Vâlcea",
    "Topalu, Constanța",
    "Topana, Olt",
    "Topleț, Caraș-Severin",
    "Topliceni, Buzău",
    "Toplița, Hunedoara",
    "Topolog, Tulcea",
    "Topolovățu Mare, Timiș",
    "Toporu, Giurgiu",
    "Topraisar, Constanța",
    "Tormac, Timiș",
    "Tortoman, Constanța",
    "Totești, Hunedoara",
    "Traian Vuia, Timiș",
    "Traian, Bacău",
    "Traian, Brăila",
    "Traian, Ialomița",
    "Traian, Olt",
    "Traian, Teleorman",
    "Treznea, Sălaj",
    "Trifești, Iași",
    "Trifești, Neamț",
    "Tritenii de Jos, Cluj",
    "Trivalea-Moșteni, Teleorman",
    "Troianul, Teleorman",
    "Trușești, Botoșani",
    "Tudor Vladimirescu, Brăila",
    "Tudor Vladimirescu, Galați",
    "Tudora, Botoșani",
    "Tufeni, Olt",
    "Tufești, Brăila",
    "Țuglui, Dolj",
    "Tulca, Bihor",
    "Tulgheș, Harghita",
    "Tulnici, Vrancea",
    "Tulucești, Galați",
    "Tunari, Ilfov",
    "Tupilați, Neamț",
    "Turburea, Gorj",
    "Turcinești, Gorj",
    "Turcoaia, Tulcea",
    "Turdaș, Hunedoara",
    "Tureni, Cluj",
    "Turia, Covasna",
    "Turnu Roșu, Sibiu",
    "Turnu Ruieni, Caraș-Severin",
    "Turț, Satu Mare",
    "Turulung, Satu Mare",
    "Tușnad, Harghita",
    "Țuțora, Iași",
    "Tutova, Vaslui",
    "Tuzla, Constanța",
    "Ucea, Brașov",
    "Uda, Argeș",
    "Uda-Clocociov, Teleorman",
    "Udești, Suceava",
    "Uileacu de Beiuș, Bihor",
    "Uivar, Timiș",
    "Ulieș, Harghita",
    "Uliești, Dâmbovița",
    "Ulma, Suceava",
    "Ulmeni, Buzău",
    "Ulmeni, Călărași",
    "Ulmi, Dâmbovița",
    "Ulmi, Giurgiu",
    "Ulmu, Brăila",
    "Ulmu, Călărași",
    "Umbrărești, Galați",
    "Ungheni, Argeș",
    "Ungheni, Iași",
    "Ungra, Brașov",
    "Unguraș, Cluj",
    "Ungureni, Bacău",
    "Ungureni, Botoșani",
    "Unguriu, Buzău",
    "Unirea, Alba",
    "Unirea, Brăila",
    "Unirea, Călărași",
    "Unirea, Dolj",
    "Unțeni, Botoșani",
    "Urdari, Gorj",
    "Urecheni, Neamț",
    "Urechești, Bacău",
    "Urechești, Vrancea",
    "Uriu, Bistrița-Năsăud",
    "Urmeniș, Bistrița-Năsăud",
    "Urzica, Olt",
    "Urziceni, Satu Mare",
    "Urzicuța, Dolj",
    "Ususău, Arad",
    "Văcăreni, Tulcea",
    "Văcărești, Dâmbovița",
    "Văculești, Botoșani",
    "Vad, Cluj",
    "Vădastra, Olt",
    "Vădăstrița, Olt",
    "Vădeni, Brăila",
    "Vadu Crișului, Bihor",
    "Vadu Izei, Maramureș",
    "Vadu Moldovei, Suceava",
    "Vadu Moților, Alba",
    "Vadu Pașii, Buzău",
    "Vadu Săpat, Prahova",
    "Văgiulești, Gorj",
    "Vaideeni, Vâlcea",
    "Vâlcănești, Prahova",
    "Valcani, Timiș",
    "Valcău de Jos, Sălaj",
    "Vâlcele, Covasna",
    "Vâlcele, Olt",
    "Vâlcelele, Buzău",
    "Vâlcelele, Călărași",
    "Valea Argovei, Călărași",
    "Valea Călugărească, Prahova",
    "Valea Chioarului, Maramureș",
    "Valea Ciorii, Ialomița",
    "Valea Crișului, Covasna",
    "Valea Danului, Argeș",
    "Valea Doftanei, Prahova",
    "Valea Dragului, Giurgiu",
    "Valea Iașului, Argeș",
    "Valea Ierii, Cluj",
    "Valea Largă, Mureș",
    "Valea Lungă, Alba",
    "Valea Lungă, Dâmbovița",
    "Valea Lupului, Iași",
    "Valea Măcrișului, Ialomița",
    "Valea Mare Pravăț, Argeș",
    "Valea Mare, Covasna",
    "Valea Mare, Dâmbovița",
    "Valea Mare, Olt",
    "Valea Mare, Vâlcea",
    "Valea Mărului, Galați",
    "Valea Moldovei, Suceava",
    "Valea Nucarilor, Tulcea",
    "Valea Râmnicului, Buzău",
    "Valea Salciei, Buzău",
    "Valea Sării, Vrancea",
    "Valea Seacă, Bacău",
    "Valea Seacă, Iași",
    "Valea Stanciului, Dolj",
    "Valea Teilor, Tulcea",
    "Valea Ursului, Neamț",
    "Valea Viilor, Sibiu",
    "Valea Vinului, Satu Mare",
    "Văleni, Neamț",
    "Văleni, Olt",
    "Văleni, Vaslui",
    "Văleni-Dâmbovița, Dâmbovița",
    "Vălișoara, Hunedoara",
    "Văliug, Caraș-Severin",
    "Valu lui Traian, Constanța",
    "Vama Buzăului, Brașov",
    "Vama, Satu Mare",
    "Vama, Suceava",
    "Vânători, Galați",
    "Vânători, Iași",
    "Vânători, Mehedinți",
    "Vânători, Mureș",
    "Vânători, Vrancea",
    "Vânători-Neamț, Neamț",
    "Vânătorii Mici, Giurgiu",
    "Vânjuleț, Mehedinți",
    "Vărădia de Mureș, Arad",
    "Vărădia, Caraș-Severin",
    "Vărăști, Giurgiu",
    "Vărbilău, Prahova",
    "Vârciorog, Bihor",
    "Vârfu Câmpului, Botoșani",
    "Vârfuri, Dâmbovița",
    "Vârfurile, Arad",
    "Vărgata, Mureș",
    "Vârghiș, Covasna",
    "Variaș, Timiș",
    "Vârlezi, Galați",
    "Vărșag, Harghita",
    "Vârșolț, Sălaj",
    "Vârteșcoiu, Vrancea",
    "Vârtoape, Teleorman",
    "Vârtop, Dolj",
    "Vârvoru de Jos, Dolj",
    "Vasilați, Călărași",
    "Vața de Jos, Hunedoara",
    "Vătava, Mureș",
    "Vatra Moldoviței, Suceava",
    "Vedea, Argeș",
    "Vedea, Giurgiu",
    "Vedea, Teleorman",
    "Vela, Dolj",
    "Verbița, Dolj",
    "Verești, Suceava",
    "Verguleasa, Olt",
    "Vermeș, Caraș-Severin",
    "Vernești, Buzău",
    "Vețca, Mureș",
    "Vețel, Hunedoara",
    "Vetiș, Satu Mare",
    "Vetrișoaia, Vaslui",
    "Vicovu de Jos, Suceava",
    "Victor Vlad Delamarina, Timiș",
    "Victoria, Brăila",
    "Victoria, Iași",
    "Vidra, Alba",
    "Vidra, Ilfov",
    "Vidra, Vrancea",
    "Viile Satu Mare, Satu Mare",
    "Viișoara, Bihor",
    "Viișoara, Botoșani",
    "Viișoara, Cluj",
    "Viișoara, Mureș",
    "Viișoara, Teleorman",
    "Viișoara, Vaslui",
    "Vima Mică, Maramureș",
    "Vinderei, Vaslui",
    "Vinga, Arad",
    "Vintilă Vodă, Buzău",
    "Vintileasca, Vrancea",
    "Vințu de Jos, Alba",
    "Viperești, Buzău",
    "Vișani, Brăila",
    "Vișeu de Jos, Maramureș",
    "Vișina Nouă, Olt",
    "Vișina, Dâmbovița",
    "Vișina, Olt",
    "Vișinești, Dâmbovița",
    "Viștea, Brașov",
    "Vitănești, Teleorman",
    "Vitomirești, Olt",
    "Vizantea-Livezi, Vrancea",
    "Viziru, Brăila",
    "Vlad Țepeș, Călărași",
    "Vlădaia, Mehedinți",
    "Vlădeni, Botoșani",
    "Vlădeni, Dâmbovița",
    "Vlădeni, Ialomița",
    "Vlădeni, Iași",
    "Vlădești, Argeș",
    "Vlădești, Galați",
    "Vlădești, Vâlcea",
    "Vlădila, Olt",
    "Vladimir, Gorj",
    "Vladimirescu, Arad",
    "Vlăsinești, Botoșani",
    "Voicești, Vâlcea",
    "Voila, Brașov",
    "Voineasa, Olt",
    "Voineasa, Vâlcea",
    "Voinești, Dâmbovița",
    "Voinești, Iași",
    "Voinești, Vaslui",
    "Voiteg, Timiș",
    "Voitinel, Suceava",
    "Voivodeni, Mureș",
    "Voloiac, Mehedinți",
    "Volovăț, Suceava",
    "Vorniceni, Botoșani",
    "Vorona, Botoșani",
    "Vorța, Hunedoara",
    "Voșlăbeni, Harghita",
    "Vrâncioaia, Vrancea",
    "Vrani, Caraș-Severin",
    "Vrata, Mehedinți",
    "Vulcan, Brașov",
    "Vulcana-Băi, Dâmbovița",
    "Vulcana-Pandele, Dâmbovița",
    "Vulpeni, Olt",
    "Vultureni, Bacău",
    "Vultureni, Cluj",
    "Vulturești, Argeș",
    "Vulturești, Olt",
    "Vulturești, Suceava",
    "Vulturești, Vaslui",
    "Vulturu, Constanța",
    "Vulturu, Vrancea",
    "Vurpăr, Sibiu",
    "Vutcani, Vaslui",
    "Zăbala, Covasna",
    "Zăbrani, Arad",
    "Zădăreni, Arad",
    "Zagăr, Mureș",
    "Zagon, Covasna",
    "Zagra, Bistrița-Năsăud",
    "Zalha, Sălaj",
    "Zam, Hunedoara",
    "Zâmbreasca, Teleorman",
    "Zamostea, Suceava",
    "Zănești, Neamț",
    "Zăpodeni, Vaslui",
    "Zărand, Arad",
    "Zărnești, Buzău",
    "Zătreni, Vâlcea",
    "Zau de Câmpie, Mureș",
    "Zăvoaia, Brăila",
    "Zăvoi, Caraș-Severin",
    "Zemeș, Bacău",
    "Zerind, Arad",
    "Zetea, Harghita",
    "Ziduri, Buzău",
    "Zimandu Nou, Arad",
    "Zimbor, Sălaj",
    "Zorleni, Vaslui",
    "Zorlențu Mare, Caraș-Severin",
    "Zvoriștea, Suceava",
]